import axios from 'axios';

export const request = async (
    endpoint,
    method,
    data = null,
    params = null,
    header = null
) => {
    const result = await axios(`https://api.cm.kg/${endpoint}`,{
    // const result = await axios(`http://192.168.3.8:5000/${endpoint}`,{
    // const result = await axios(`http://localhost:5000/${endpoint}`,{
      method: method,
      data: data,
      params: params,
      headers: header,
    })
    .then((res) => res.data)
   return result
  };
