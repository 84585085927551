import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="979038049246-3lv3fn650nar1ppbjsutgvlk7ep3l5a6.apps.googleusercontent.com">
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
      </GoogleOAuthProvider>
  </React.StrictMode>
);
