import LabelBrand from "../../components/label-brand";
import {Alert, Button, ButtonGroup} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import {useState} from "react";
import styled from "styled-components";

const AlertContainer = styled.div`
  position: absolute;
  top: -13px;
  left: 174px;
  width: 205px;
  z-index: 1;
`


const FileListItem = ({el, getImageFile, ConfirmModalOpen}) => {
    // Alert Copy
    const [showTopBtn, setShowTopBtn] = useState(false);
    const showAlert = () => {
        setShowTopBtn(true)
        setTimeout(() => setShowTopBtn(false), 3000)
    }

    // Copy name
    const copyName = (text) => {
        console.log(text)
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Добавляем текстовое поле в DOM и выделяем его содержимое
        document.body.appendChild(textArea);
        textArea.select();

        // Копируем текст в буфер обмена
        document.execCommand('copy');

        // Удаляем текстовое поле из DOM
        document.body.removeChild(textArea);
        showAlert()
    }
    return (
        <div key={el} className={"brand-name-list"} style={{width:500}}>
            <LabelBrand brandName={el} customWidth={500} />
            <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled elevation buttons"
                className="dnd-btn-copy"
            >
                <Button onClick={() => copyName(`${el}`)}><ContentCopyIcon/></Button>
                <Button onClick={() => getImageFile(el)}><RemoveRedEyeIcon /></Button>
                <Button onClick={() => ConfirmModalOpen(el)}><HighlightOffIcon /></Button>
                <AlertContainer>
                    {showTopBtn && (<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Скопированно {el}
                        </Alert>
                    )}
                </AlertContainer>
            </ButtonGroup>
        </div>
    )
}

export {FileListItem}