import "./style.css"
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getAllChanels, getChanel, updateChanel} from "../../API/api";
import {Button, TextField} from "@mui/material";

const Settings = () => {
    const {activeChanel} = useSelector((store) => store.chanel);
    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    // Data Group
    const [id, setId] = useState()
    const [chanel, setChanel] = useState('')
    const [ipAddress, setIpAddress] = useState('')
    const [rekBlock, setRekBlock] = useState([])
    const [clipsUrl, setClipsUrl] = useState([])
    const [bannerUrl, setBannerUrl] = useState([])
    const [bannerClipUrl, setBannerClipUrl] = useState([])
    const [bannerFileUrl, setBannerFileUrl] = useState([])
    const [tickerUrl, setTickerUrl] = useState([])
    const [tickerFileUrl, setTickerFileUrl] = useState([])
    const [timeBlock, setTimeBlock] = useState({})
    // const timeBlock = {}

    //Day block time
    const [timeBlockSun, setTimeBlockSun] = useState([])
    const [timeBlockMon, setTimeBlockMon] = useState([])
    const [timeBlockTue, setTimeBlockTue] = useState([])
    const [timeBlockWen, setTimeBlockWen] = useState([])
    const [timeBlockThu, setTimeBlockThu] = useState([])
    const [timeBlockFri, setTimeBlockFri] = useState([])
    const [timeBlockSat, setTimeBlockSat] = useState([])

    // Other needs
    const [dayRek, setDayRek] = useState('ВС')
    let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']


    // const [chanels, setChanels] = useState([])
    const chanelGet = async () => {
        const data = await getChanel(chanels[activeChanel]?.id);
        // setChanels(data)
        setId(data.id)
        setChanel(data.chanel)
        setIpAddress(data.ipAddress)
        setRekBlock(data.rekBlock)
        setClipsUrl(data.clipsUrl)
        setBannerUrl(data.bannerUrl)
        setBannerClipUrl(data.bannerClipUrl)
        setBannerFileUrl(data.bannerFileUrl)
        setTickerUrl(data.tickerUrl)
        setTickerFileUrl(data.tickerFileUrl)
        setTimeBlock(data.timeBlock)
    }
    useEffect(() => {
        if (chanels.length > 0) {
            chanelGet()
        }
    },[activeChanel, chanels])

    // Change Function day block time

    const getBlockValue = (block, index) => {
        switch (dayRek) {
            case 'ВС' :
                return timeBlockSun[index]?.[block]
            case 'ПН' :
                return timeBlockMon[index]?.[block]
            case 'ВТ' :
                return timeBlockTue[index]?.[block]
            case 'СР' :
                return timeBlockWen[index]?.[block]
            case 'ЧТ' :
                return timeBlockThu[index]?.[block]
            case 'ПТ' :
                return timeBlockFri[index]?.[block]
            case 'СБ' :
                return timeBlockSat[index]?.[block]
        }
    }

    const changeValue = (block, e) => {
        switch (dayRek) {
            case 'ВС' :
                setTimeBlockSun(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'ПН' :
                setTimeBlockMon(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'ВТ' :
                setTimeBlockTue(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'СР' :
                setTimeBlockWen(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'ЧТ' :
                setTimeBlockThu(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'ПТ' :
                setTimeBlockFri(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
            case 'СБ' :
                setTimeBlockSat(oldArray =>
                    oldArray.map(item =>
                        typeof item[block] !== "undefined"
                            ? {[block]: e.target.value} : item
                    )
                )
                break
        }
    }
    useEffect(() => {
        if (timeBlock) {
            days.map((day, index) => {
                Object.values(timeBlock).map((block, index) => {
                    switch (index) {
                        case 0 :
                            setTimeBlockSun(block)
                            break
                        case 1 :
                            setTimeBlockMon(block)
                            break
                        case 2 :
                            setTimeBlockTue(block)
                            break
                        case 3 :
                            setTimeBlockWen(block)
                            break
                        case 4 :
                            setTimeBlockThu(block)
                            break
                        case 5 :
                            setTimeBlockFri(block)
                            break
                        case 6 :
                            setTimeBlockSat(block)
                            break
                    }
                })
            })
        }
        if (!timeBlock) {
            days.map((day, index) => {
                let dayBlock = []
                rekBlock.map((block) => {
                    dayBlock.push({[block] : "0"})
                })
                switch (day) {
                    case 'ВС' :
                        setTimeBlockSun(dayBlock)
                        break
                    case 'ПН' :
                        setTimeBlockMon(dayBlock)
                        break
                    case 'ВТ' :
                        setTimeBlockTue(dayBlock)
                        break
                    case 'СР' :
                        setTimeBlockWen(dayBlock)
                        break
                    case 'ЧТ' :
                        setTimeBlockThu(dayBlock)
                        break
                    case 'ПТ' :
                        setTimeBlockFri(dayBlock)
                        break
                    case 'СБ' :
                        setTimeBlockSat(dayBlock)
                        break
                }

            })
        }
    }, [rekBlock])
    useEffect(() => {
        setTimeBlock({
            ['ВС']: timeBlockSun,
            ['ПН']: timeBlockMon,
            ['ВТ']: timeBlockTue,
            ['СР']: timeBlockWen,
            ['ЧТ']: timeBlockThu,
            ['ПТ']: timeBlockFri,
            ['СБ']: timeBlockSat
        })
    },[timeBlockSun,timeBlockMon,timeBlockTue,timeBlockWen,timeBlockThu,timeBlockFri,timeBlockSat])

    // Update function
    const saveSettings = async () => {
        let data = {}
        // data.id = id
        data.chanel = chanel
        data.ipAddress = ipAddress
        data.rekBlock = rekBlock
        data.clipsUrl = clipsUrl
        data.bannerUrl = bannerUrl
        data.bannerClipUrl = bannerClipUrl
        data.timeBlock = timeBlock
        data.bannerFileUrl = bannerFileUrl
        data.tickerUrl = tickerUrl
        data.tickerFileUrl = tickerFileUrl
        // console.log(data)
        await updateChanel(localStorage.getItem("access_token"), id, data)
        alert('Изменения сохранены.')
    }
    // console.log(timeBlockSun)

    // Функция Минус
    // const testMinus = () => {
    //     timeBlockSun.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockMon.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockTue.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockWen.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockThu.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockFri.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    //     timeBlockSat.map((obj) => {
    //         Object.keys(obj).forEach(el => obj[el] = `${Number(obj[el]) - 50}`)
    //     })
    // }

    return (
        <div className="settings_page container">
            <div className="settings-head">
                Настройки телеканала: {chanel}
            </div>
            <div className="settings-table">
                <div>
                    <div className="settings-table_left">
                        IP Адрес Сервера:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="IP Адрес Сервера"
                            variant="outlined"
                            value={ipAddress}
                            onChange={(e) => {
                                setIpAddress(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Рекламные блоки:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Рекламные блоки"
                            variant="outlined"
                            value={rekBlock.join('; ')}
                            onChange={(e) => {
                                setRekBlock(e.target.value.split(/\s*;\s*/));
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Папка клипы:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Папка клипы"
                            variant="outlined"
                            value={clipsUrl}
                            onChange={(e) => {
                                setClipsUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Папка банеры:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Папка банеры"
                            variant="outlined"
                            value={bannerUrl}
                            onChange={(e) => {
                                setBannerUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Подложка банеры:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Подложка баннеры"
                            variant="outlined"
                            value={bannerClipUrl}
                            onChange={(e) => {
                                setBannerClipUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Файл банеры:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Файл баннеры"
                            variant="outlined"
                            value={bannerFileUrl}
                            onChange={(e) => {
                                setBannerFileUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Папка Бегушка:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Папка Бегушка"
                            variant="outlined"
                            value={tickerUrl}
                            onChange={(e) => {
                                setTickerUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="settings-table_left">
                        Файл бегушка:
                    </div>
                    <div className="settings-table_right">
                        <TextField
                            className="settings-table_input"
                            type="text"
                            label="Файл баннеры"
                            variant="outlined"
                            value={tickerFileUrl}
                            onChange={(e) => {
                                setTickerFileUrl(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="settings-head">
                    Настройка длины рекламного блока
                </div>
                <div className="settings-day-btns">
                    {days.map((el) => (
                        <Button key={el} variant={dayRek===el ? "contained" : "outlined"} onClick={()=> setDayRek(el)}>{el}</Button>
                    ))}
                </div>
                <div className="settings-day-blocks">
                    {rekBlock.map((block,index) => (
                        <div className="settings-day-blocks_items" key={index + block}>
                            <p>{block}</p>
                            <TextField
                                className="settings-day-block_input"
                                type="text"
                                variant="outlined"
                                value={getBlockValue(block, index)}
                                onChange={(e) => {
                                    changeValue(block, e)
                                }}
                            />
                        </div>
                    ))}
                </div>
                {/*<Button variant="contained" onClick={() => testMinus()}>-50 все блоки</Button>*/}
            </div>
            <Button variant="contained" onClick={() => saveSettings()}>Сохранить</Button>
            <Button variant="outlined" onClick={() => chanelGet()}>Отмена</Button>
        </div>
    )
}

export default Settings