import './style.css'
import ReactHlsPlayer from "react-hls-player";
import {Button} from "@mui/material";
import {useState} from "react";

const WatchPage = () => {
    const [btn, setBtn] = useState(1)
    const streamLink = () => {
        if(btn === 1) {return("https://record.cm.kg/live/LyubimiyCTC.stream/playlist.m3u8")}
        if(btn === 2) {return("https://record.cm.kg/live/LyubimiySD.stream/playlist.m3u8")}
        if(btn === 3) {return("https://record.cm.kg/live/CineMaxSD.stream/playlist.m3u8")}
    }
    // const streamName = () => {
    //     if(btn === 1) {return("СТС")}
    //     if(btn === 2) {return("Любимый")}
    //     if(btn === 3) {return("CineMax")}
    // }

    return (
        <div className="settings_page container">
            <div className="choose-btns">
                <Button variant={btn === 1 ? "contained" : "outlined"} onClick={()=>setBtn(1)}>СТС</Button>
                <Button variant={btn === 2 ? "contained" : "outlined"} onClick={()=>setBtn(2)}>Любимый</Button>
                <Button variant={btn === 3 ? "contained" : "outlined"} onClick={()=>setBtn(3)}>CineMax</Button>
            </div>
            <div className="video-section">
                <ReactHlsPlayer
                    src={streamLink()}
                    autoPlay={false}
                    controls={true}
                    width="800px"
                    height="auto"
                />
            </div>
            {/*<div className="video-section">*/}
            {/*    <p>Любимый</p>*/}
            {/*    <ReactHlsPlayer*/}
            {/*        src="https://record.cm.kg/live/LyubimiySD.stream/playlist.m3u8"*/}
            {/*        autoPlay={false}*/}
            {/*        controls={true}*/}
            {/*        width="800px"*/}
            {/*        height="auto"*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className="video-section">*/}
            {/*    <p>CineMax</p>*/}
            {/*    <ReactHlsPlayer*/}
            {/*        src="https://record.cm.kg/live/CineMaxSD.stream/playlist.m3u8"*/}
            {/*        autoPlay={false}*/}
            {/*        controls={true}*/}
            {/*        width="800px"*/}
            {/*        height="auto"*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    )
}

export default WatchPage