import "./style.css"
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import MpBrand from "../../modules/mp-brand";
import {Button, Input, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import parse from 'paste-from-excel'
import {
    addNewMP,
    getAllChanels,
    getDayRek,
    getDayRekBlock,
    getMonthMP,
    getMP,
    setActiveMP,
    setUpdateMP
} from "../../API/api";
import MpTable from "../../modules/mp-table";

const EditMP = () => {
    const [clips, setClips] = useState([]);
    const { userName } = useSelector((store) => store.userName)
    const [currentData, setCurrentData] = useState({})
    const [status, setStatus] = useState()
    const [dayName, setDayName] = useState('ПН')
    const [rekIndex, setRekIndex] = useState(0)
    // const [reklama, setReklama] = useState([])

    const navigate = useNavigate()

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch,
        reset,
    } = useForm();

    const {id} = useParams();
    const {activeMonth} = useSelector((store) => store.month)
    const {activeChanel} = useSelector((store) => store.chanel)

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const [dayPerMonth, setDayPerMonth] = useState([])

    const currentDays = () => {
        setDayPerMonth([])
        const lastDayMonth = new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate()
        for (let i = 1; i <= lastDayMonth; i++) {
            setDayPerMonth(oldArray => [...oldArray, i])
        }
    }

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    useEffect(() => {
        currentDays()
    }, [activeMonth])

    //Убераем пробелы из ячеек???
    const rekSpaceDelete = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            // Если элемент массива - объект
            if (typeof arr[i] === 'object' && arr[i] !== null) {
                // Проход по свойствам объекта
                for (let key in arr[i]) {
                    // Если свойство является строкой
                    if (typeof arr[i][key] === 'string') {
                        // Удаление пробелов из значения
                        arr[i][key] = arr[i][key].replace(/\s+/g, '');
                    }
                }
            }
        }
        return arr
    }


    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            //await setClips(data.clips);
            // data.chanel = activeChanel
            // data.month = monthName(activeMonth)
            data.user = userName
            data.clips = clips
            let rek = data.reklama
            data.reklama = rekSpaceDelete(rek)
            // let rek = data.reklama
            // data.reklamaFull = addClipInRek(rek, data.clips)
            // console.log(data.reklama)
            setCurrentData(data)
            // let testRek = addClipInRek(data.reklama, data.clips)
            // setTest(testRek)
            //console.log(`qwe ${data}`);
            await setUpdateMP(localStorage.getItem("access_token"), id, data);
            //await addNewMP(data);
            // setClipsField([]);
            // handleClose();
            navigate("/mp")
        } catch (error) {
            console.log(data)
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    // const inWork =  async () => {
    //     if (errors?.name) {
    //         console.log(errors?.name?.message);
    //     }
    //     try {
    //         await setActiveMP(id, {status: !status})
    //         setStatus(!status)
    //     } catch (error) {
    //         console.dir(error);
    //         throw new Error("Что-то не так с пост запросом");
    //     }
    // }
     // const asd = new Date(activeMonth.getFullYear(), activeMonth.getMonth()+1, 0)
    // console.log()

    // const addClipInRek = (rek, clips) => {
    //     rek.map((day) => {
    //         Object.keys(day).forEach((key) => {
    //             clips.map((el) => {
    //                 if (day[key] === el.short) {
    //                     return day[key] = el
    //                 }
    //                 if (day[key] === '') {
    //                     delete day[key]
    //                 }
    //             })
    //             return day[key]
    //         })
    //         return day
    //     })
    //     return rek
    // }

    // TEST COPY INPUT VALUE
    // const [inputvalue, setinputvalue] = useState({
    //     inputs: [
    //         { },
    //     ],
    // });
    // const handlePaste = (index, elm, e, i) => {
    //     return parse(e);
    // };
    //
    // const handlePaste1 = (index, elm, e, i) => {
    //     const newList4 = inputvalue.inputs.map((item, i) => {
    //         if (index === i) {
    //             const updatedItem = {
    //                 ...item,
    //                 [elm]: e.target.value,
    //             };
    //             // console.log(updatedItem);
    //             return updatedItem;
    //         }
    //         return item;
    //     });
    //     //console.log("newList4", newList4);
    //
    //     setinputvalue((prevData) => ({
    //         ...prevData,
    //         inputs: newList4,
    //     }));
    // };


    // Get Data
    const [dataMp, setDataMp] = useState(null)
    // const [reklama, setReklama] = useState([])
    const getDataMp = async () => {
        const data = await getMP(localStorage.getItem("access_token"), id);
        setDataMp(data)
    }
    useEffect(() => {
        getDataMp();
    },[id]);

    useEffect(() => {
        if (dataMp) {
            setClips(dataMp?.clips)
            setStatus(dataMp?.status)
            // setReklama(dataMp?.reklama)
            reset(dataMp)
        }
    },[dataMp])

    // //Get Data Block
    const [rekBlockList, setRekBlockList] = useState([])
    const month = monthName(activeMonth)
    // const getRekBlockList = async (day, block) => {
    //     const data = await getDayRekBlock(`${month}`, `${chanels[activeChanel]?.id}`, `${day}`,`${block}`)
    //     setRekBlockList(data)
    // }
    // Function ListBlock

    const [mpList, setMpList] = useState([])
    const getMpList = async () => {
        const data = await getMonthMP(localStorage.getItem("access_token"),
            `${month}`, `${chanels?.[activeChanel]?.id}`);
        setMpList(data)
    }
    useEffect(() => {
        getMpList();
    },[]);

    // const {plchold, setPlchold} = useState({})
    // const getRemainingTime = (day) => {
    //     let arr = []
    //     mpList?.filter((el) => {
    //         if (el.reklama[day]) {
    //             //
    //             arr.push(Number(el.reklama[day].length))
    //         }
    //     })
    //     let result1 = arr.length > 0 ? arr?.reduce((a, b) => a + b) : ""
    //     let result = 180 - result1
    //     return result
        // setPlchold((prevState) => ({
        //         ...prevState,
        //         [day]: result
        //     })
        // )
    // }

    // useEffect(() => {
    //     chanels?.[activeChanel]?.rekBlock?.map((res, index) => {
    //         {
    //             dayPerMonth?.map((elm, i) => {
    //                 const days = `${elm} ${res}`
    //                 const value = getRemainingTime(days)
    //                 console.log(days)
    //                 setPlchold((prevState) => {
    //                     return {
    //                     ...prevState,
    //                     [days]: value}
    //                 })
    //             })
    //         }})
    //
    // }, [])

    const blockList = () => {
        const arr = []
        rekBlockList?.map((el) => {
            const data = Object.values(el)
            if (data.length > 0) {
                arr.push(Number(data[0].length))
            }
        })

        const timeLimit = chanels[activeChanel]?.timeBlock?.[dayName]?.[rekIndex]
            ? Object.values(chanels[activeChanel]?.timeBlock?.[dayName]?.[rekIndex])[0]
            : 0
        let result1 = arr.length > 0 ? arr?.reduce((a, b) => a + b) : ""
        // let result = 180 - result1
        let result = timeLimit - result1
        return result

    }

    // Покраска блока
    const bgc = (time) => {
        if (time > 61) {
            return ""
        }
        if (46 <= time && time <= 60) {
            return "deepskyblue"
        }
        if (31 <= time && time <= 45) {
            return "green"
        }
        if (21 <= time && time <= 30) {
            return "yellow"
        }
        if (11 <= time && time <= 20) {
            return "orange"
        }
        if (1 <= time && time <= 10) {
            return "red"
        }
        if ( time <= 0) {
            return "black"
        }
    }

    // console.log(new Date(new Date().setMilliseconds(8 * 60 * 60 * 1000)).getDate())
    // console.log(getRemainingTime("1 6:55"))

    // const [inputValue, setInputValue] = useState()
    // const getValueInput = (elm, res) => {
    //     const data = reklama?.[elm]
    //     const data2 = data?.[res]
    //     if (data2) {
    //         return data2.short
    //     }
    // }
    // console.log(rekBlockList)

    return (
        <div className="editMP container">
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mp-head">
                <div className="mp-head-left">
                    {/*{id}*/}
                    {/*{monthName(activeMonth)}*/}
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Телеканал:</div>
                        <div className="mp-head_text_right">{chanels[activeChanel]?.chanel}</div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Пользователь:</div>
                        <div className="mp-head_text_right">{dataMp?.user}</div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Брэнд:</div>
                        <div className="mp-head_text_right">
                            <Input
                            className="mp-head_input"
                            placeholder="Название"
                            {...register("name",{
                                required: true
                            })}
                            />
                        </div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Status:</div>
                        <div className="mp-head_text_right">{dataMp?.status ? "Активный" : "Не в работе"}</div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Позиция:</div>
                        <div className="mp-head_text_right">
                            <Input
                                className="mp-head_input"
                                placeholder="Позиция"
                                {...register("position")}
                            />
                        </div>
                    </div>
                    <MpBrand
                        activeChanel={dataMp?.chanel}
                        activeMonth={monthName(activeMonth)}
                        clips={clips}
                        setClips={setClips}

                    />
                </div>
                <div className={`mp-head-right ${bgc(Number(blockList()))}`}>
                    <span>Ролики в блоке</span>
                    {rekBlockList?.map((el, i) => {
                        const data = Object.values(el)
                        if (data.length > 0) {
                            return (<p key={i}>{data[0].name} : {data[0].length}c</p>)
                        }
                    })}
                    <span>Осталось: {blockList()} сек</span>
                </div>
            </div>

                <MpTable
                    chanels={chanels}
                    dayPerMonth={dayPerMonth}
                    register={register}
                    setValue={setValue}
                    setRekBlockList={setRekBlockList}
                    clips={clips}
                    id={id}
                    watch={watch}
                    setDayName={setDayName}
                    setRekIndex={setRekIndex}
                />
            <Button type={"submit"} variant="contained">Сохранить</Button>
                {/*<Button variant="contained" onClick={() => inWork()}>Work</Button>*/}
                <Button variant="outlined" onClick={() => navigate(-1)}>Отмена</Button>
            </form>
        </div>
    )
}

export default EditMP