import StartEndDate from "../start-end-date";
import CalendarAddDay from "../calendar-add-day";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  flex-direction: column;
`

const SelectDates = ({daysWeekName, selectDates, setSelectDates, customArrDayMonth}) => {
    return (
        <Container>
            <div>
                <StartEndDate setSelectDates={setSelectDates} selectDates={selectDates} />
            </div>
            <div className="add-calendar">
                {daysWeekName.map((el, index) => (
                    <div key={index} className="addBanner-table_dayName">{el}</div>
                ))}
                {customArrDayMonth.map((el, index) => (
                    <CalendarAddDay key={index} el={el} index={index} setSelectDates={setSelectDates} selectDates={selectDates} />
                ))}
            </div>
        </Container>
    )
}

export {SelectDates}