import './style.css'
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const CalendarAddDay = ({el, index, setSelectDates, selectDates}) => {
    const [selected, setSelected] = useState(false)
    const {activeMonth} = useSelector((store) => store.month);
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedYear, setSelectedYear] = useState('')

    useEffect(() => {
        setSelectedMonth(new Date(activeMonth).getMonth())
        setSelectedYear(new Date(activeMonth).getFullYear())
        setSelected(false)
    }, [activeMonth])

    useEffect(() => {
        setSelected(false)
        selectDates.map((elem) => {
                if(elem === el) {
                    setSelected(true)
                }
            }
        )
    }, [selected, selectDates])

    // Ограничение на преведущие дни
    const lastDateChk = () => {
        if (
            new Date().getFullYear() === selectedYear &&
            new Date().getMonth() === selectedMonth &&
            new Date().getDate() <= el
        ) {return true}
        if (
            new Date().getFullYear() < selectedYear
        ) {return true}
        if (
            new Date().getFullYear() <= selectedYear &&
            new Date().getMonth() < selectedMonth
        ) {return true}
        if (
            new Date().getFullYear() >= selectedYear &&
            new Date().getMonth() > selectedMonth
        ) {return false}
        // else {return false}
    }

    const addToArraySelectedDay = () => {
        if (
            typeof el === "number" &&
            lastDateChk()
        )
        {
            if (!selected) {
                setSelectDates(oldArray => [...oldArray, el])
                setSelected(!selected)
            }
            if (selected) {
                setSelectDates(selectDates.filter(sDay => sDay != el))
                setSelected(!selected)
            }
        }
    }

    // console.log(typeof el === "number")

    return (
          <div key={index} className={`addBanner-table_value ${selected ? "green" : ""}`} onClick={() => addToArraySelectedDay()}>
              {el}
          </div>

    )
}

export default CalendarAddDay