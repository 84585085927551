import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userName: '',
    email: '',
    id: '',
};

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        changeUserName: (state, action) => {
            state.userName = action.payload;
            // localStorage.setItem("activeChanel", action.payload);
        },
        changeEmail: (state, action) => {
            state.email = action.payload;
        },
        changeUserId: (state, action) => {
            state.id = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export const {
    changeUserName,
    changeEmail,
    changeUserId,
} = profileSlice.actions;

export default profileSlice.reducer;
