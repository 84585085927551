import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";


const ConfirmModal = ({openConfirmModal, setOpenConfirmModal, link}) => {
    const closeConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    const agree = () => {
        link()
        setOpenConfirmModal(false)
    }

    return (
        <Dialog
            open={openConfirmModal}
            onClose={closeConfirmModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Вы уверены, что хотите удалить?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    После подтверждения, у Вас не будет возможности отменить удаление.<br/>
                    Данные будут утеряны безвозвратно!!! Подумайте хорошо прежде, чем согласится!!!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeConfirmModal} autoFocus>Отмена</Button>
                <Button onClick={agree}>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal