import './style.css'
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {getAllChanels, getDayRekBlock} from "../../API/api";

const MainPageReklist = ({day, block, chanels, time}) => {
    const {activeChanel} = useSelector((store) => store.chanel);
    const {activeMonth} = useSelector((store) => store.month);


    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)
    const getRekBlockList = async (day, block) => {
        const data = await getDayRekBlock(localStorage.getItem("access_token"),`${month}`, `${chanels[activeChanel]?.id}`, `${day}`,`${block}`)
        setRekBlockList(data)
    }
    // const ref = customRef;
    const ref = useRef()
    const [showRekList, setShowRekList] = useState(false)
    const [rekBlockList, setRekBlockList] = useState([])

    const handleRekDisplay = () => {
        getRekBlockList(day,block)
        setShowRekList(!showRekList)
    }

    useEffect(() => {
        if (!showRekList) return;

        const handleClick = (e) => {
            if (!ref.current) return
            if (!ref.current.contains(e.target)) {
                setShowRekList(false)
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [showRekList]);

    const bgc = (time) => {
        if (time > 61) {
            return ""
        }
        if (46 <= time && time <= 60) {
            return "deepskyblue"
        }
        if (31 <= time && time <= 45) {
            return "green"
        }
        if (21 <= time && time <= 30) {
            return "yellow"
        }
        if (11 <= time && time <= 20) {
            return "orange"
        }
        if (1 <= time && time <= 10) {
            return "red"
        }
        if ( time <= 0) {
            return "black"
        }
    }

    return (
        <div className={`mainpage-table_data ${bgc(Number(time))} ${showRekList ? "maxBorder" : ""}`} onClick={() => handleRekDisplay()} ref={ref}>
            {time}
            <div className={showRekList ? "mainpage-table_rek active" : "mainpage-table_rek"}>
                {rekBlockList?.map((el, index) => {
                    const data = Object.values(el)
                    if (data.length > 0) {
                        return (<p key={index}>{data[0].name} : {data[0].length}c</p>)
                    }
                })}
            </div>
        </div>
    )
}

export default MainPageReklist