import './style.css'
import {useEffect, useState} from "react";
import {Button, TextField} from "@mui/material";
import {addNewClips, addNewMP, getAllChanels, getChanel, uploadFile} from "../../API/api";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
import {FileUpload} from "@mui/icons-material";
import { Base64 } from "js-base64";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Test = () => {
        const [selectedFile, setSelectedFile] = useState();
        const [resizeBanner, setResizeBanner] = useState();
        const [nameBanner, setNameBanner] = useState();
        // BLOCK UPLOAD
    const {
        handleSubmit,
        formState: {errors},
    } = useForm();
    const {activeChanel} = useSelector((store) => store.chanel);

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);


    // const [clipName, setClipName] = useState("")
    // const [clipLength, setClipLength] = useState("")
    const [clipShortName, setClipShortName] = useState("")
    // const [clipChanel, setClipChanel] = useState("")
    // const [clipDirectory, setClipDirectory] = useState("")
    //END UPLOAD then down

        const changeHandler = (event) => {
            const img = event.target.files[0]
            setNameBanner(event.target.files[0].name)
            const reader = new FileReader()
            reader.onload = (e) => {
                const originalImg = new Image()
                originalImg.src = e.target.result

                compressImage(originalImg.src)
            }
            reader.readAsDataURL(img)
            // reader.readAsDataURL(file)
            // console.log(img)
            // setSelectedFile(img);
            //setIsSelected(true);
        };

        const compressImage = (base64) => {
            const canvas = document.createElement('canvas')
            const img = document.createElement('img')

            img.onload = () => {
                let width = img.width
                let height = img.height
                const maxHeight = chanels[activeChanel]?.chanel === "CTC" ? 520 : 652
                const maxWidth = chanels[activeChanel]?.chanel === "CTC" ? 928 : 1152
                // CTC 928 / 520

                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width))
                    width = maxWidth
                }
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height))
                    height = maxHeight
                }
                canvas.width = width
                canvas.height = height

                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)

                canvas.toBlob((blob) => {
                    setSelectedFile(blob);
                });

                const compressData = canvas.toDataURL('image/png', 0.7)
                // console.log(ctx)
                setResizeBanner(compressData)

            }
            img.onerror = (err) => {
                return err
            }
            img.src = base64
        }

    console.log(nameBanner?.substring(0, nameBanner?.lastIndexOf('.')))
    console.log(chanels[activeChanel]?.chanel)
        const handleSubmission = async () => {
            let data = new FormData();
            await data.append("clip_name", `${nameBanner?.substring(0, nameBanner?.lastIndexOf('.'))}.png`)
            // await data.append("file", Base64.decode(resizeBanner))
            // await data.append("file", Base64.atob(resizeBanner?.split(',').at(-1)))
            await data.append("file", selectedFile)
            await uploadFile(
                localStorage.getItem("access_token"),
                `${chanels[activeChanel]?.id}`,
                `bannerUrl`,
                data);
            alert('file upload')
        };



        // VIDEO Name + duration
    const [currentVideo, doSetCurrentVideo] = useState();
    const [currentVideoFile, doSetCurrentVideoFile] = useState();
    const [videoLength, setVideoLength] = useState();
    const [videoName, setVideoName] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();

    const getLength = video => {
        const audio = new Audio();
        audio.src = video;
        audio.onloadedmetadata = _ => {
            setVideoLength(audio.duration);
            setLoading(false);
        };
        audio.onerror = ev => {
            setError(true);
            setLoading(false);
        };
    };

    const setCurrentVideo = video => {
        setLoading(true);
        setVideoLength(null);
        setError(null);
        doSetCurrentVideo(video);
        getLength(video);
    };

    const onVideoSelected = ev => {
        setVideoName(ev.target.files[0].name)
        // console.log(ev.target.files)
        const video = URL.createObjectURL(ev.target.files[0])
        const file = ev.target.files[0]
        setCurrentVideo(video)
        doSetCurrentVideoFile(file)
    };

    if (loading) return <div>Loading ...</div>;

        // console.log(resizeBanner)

    //UPLOAD FILE FORM

    const addClip = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            // data.file = currentVideo
            // data.clip_name = videoName
            // data.clip_length = videoLength
            // data.clip_shortname = clipShortName
            // data.chanel = chanels[activeChanel].chanel
            // data.directory = "D:/uploads/clips"
            // data.status = true
            // console.log(data);

            let data = new FormData();
            data.append("clip_name", videoName)
            data.append("clip_length", videoLength)
            data.append("clip_shortname", clipShortName)
            data.append("chanel", chanels?.[activeChanel]?.chanel)
            data.append("directory", "D:/uploads/clips")
            data.append("status", true)
            data.append("file", currentVideoFile)
            // data.append("file", new Blob([currentVideo.buffer], { type: currentVideo.mimetype }))
            // console.log(data)
            // await axios.post('http://localhost:5000/clips', data, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // })

            await addNewClips(localStorage.getItem("access_token"),data);
            // navigate('/mp')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }
    // console.log(resizeBanner)

    return (
        <div className="addMP container">
            <div>
                <input type="file" name="banner" accept="image/jpeg, image/jpg, image/png" onChange={changeHandler} />
                <div>
                    <button onClick={handleSubmission}>Submit</button>
                    <img src={resizeBanner ? `${resizeBanner}`  : '' } />
                </div>
            </div>
            {/* VIDEO */}
            <hr />
            <hr />
            {/*<div>*/}
            {/*    {error &&*/}
            {/*        `Error while loading video. Format probably not supported natively.`}*/}
            {/*    <hr />*/}
            {/*    {videoLength && `Video NAme is ${videoName}`}*/}
            {/*    <hr />*/}
            {/*    {videoLength && `Video Length is ${videoLength} seconds`}*/}
            {/*    <hr />*/}
            {/*    Video SRC: {JSON.stringify(currentVideo)}*/}
            {/*    <hr />*/}
            {/*    <video src={currentVideo} width="300" autoPlay controls />*/}
            {/*    <hr />*/}
            {/*    <div>*/}
            {/*        Load video from hard drive:{" "}*/}
            {/*        <input type="file" name="video" accept="video/mp4" onChange={onVideoSelected} />*/}
            {/*    </div>*/}

            {/*</div>*/}
            <div>
                <h2>TEST UPLOAD Clips</h2>
                {videoLength ?
                    <p>{`Video NAme is ${videoName}`}</p>
                    :
                    <input type="file" name="file" accept="video/mp4, .mov" onChange={onVideoSelected} />
                }
                <TextField
                    // className="settings-table_input"
                    type="text"
                    label="Буква"
                    variant="outlined"
                    value={clipShortName}
                    onChange={(e) => {
                        setClipShortName(e.target.value);
                    }}
                />
                <Button variant="contained" onClick={handleSubmit(addClip)}>Добавить</Button>
            </div>
            <hr/>
            <hr/>
            <hr/>
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" accept="video/mp4" onChange={onVideoSelected} />
            </Button>
        </div>
    )
}

export default Test