import "./style.css"
import {useEffect, useState} from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {ru} from 'date-fns/locale'
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {changeChanel} from "../../store/chanels";
import {selectMonth} from "../../store/select-month";


const MonthSlider = () => {

    // Получаем дату
    const toDay = new Date()
    const dd = toDay.getDate()
    const mm = toDay.getMonth() + 1
    const yyyy = toDay.getFullYear()

    const testDate = new Date(yyyy, (toDay.getMonth() - 1), 1)
    // END date

    // Select Date, current month
    const [selectedDate, handleDateChange] = useState(new Date())

    const dispatch = useDispatch();

    // console.log(selectedDate)
    useEffect(() => {
        dispatch(selectMonth(`${selectedDate}`))
    }, [selectedDate])

    const monthName = (date) => (date.toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))

    const prevMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1)

    const nextMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)

    return (
        <div className="month-choose">
            <div className="month-select">
                <MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
                    <DatePicker
                            variant="inline"
                            openTo="month"
                            views={["year", "month"]}
                            label="Выбор месяца и года"
                            type="local"
                            //helperText="Start from year selection"
                            value={selectedDate}
                            onChange={handleDateChange}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className="month-slider">
                <Button variant="outlined" onClick={() => handleDateChange(prevMonth)}>{monthName(prevMonth)}</Button>
                <Button variant="contained">{monthName(selectedDate)}</Button>
                <Button variant="outlined" onClick={() => handleDateChange(nextMonth)}>{monthName(nextMonth)}</Button>
            </div>
            <div>
                <Button variant="contained" onClick={() => handleDateChange(new Date())}>Текущий месяц</Button>
            </div>
        </div>
    )
}

export default MonthSlider