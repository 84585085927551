import "./style.css"

const Footer = () => {
    return (
        <footer>
            <span>Copyright 2022.</span>
        </footer>
    )
}

export default Footer