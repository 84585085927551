import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeChanel} from "../../store/chanels";
import {getAllChanels} from "../../API/api";

const ChooseChanelModal = () => {
    const { activeChanel } = useSelector((store) => store.chanel);
    const dispatch = useDispatch();

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (activeChanel === "Choose") {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [])

    const handleClose = (chanel) => {
        dispatch(changeChanel(chanel))
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                open={open}
                /*onClose={handleClose}*/
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Выберите телеканал!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Перед выполнением дальнейших действий выберите телеканал,
                        на котором Вы хотите внести изменения!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="changeChanel">
                        {chanels.map((chanel, index) => (
                            <Button key={chanel.id} variant={Number(activeChanel) === index ? "contained" : "outlined"}
                                    onClick={() => handleClose(index)}>
                                {chanel.chanel}
                            </Button>
                        ))}

                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ChooseChanelModal