import {Button, ButtonGroup} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const BtngroupEditDelete = ({editLink, deleteLink}) => {
    return (
        <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
        >
            <Button onClick={editLink}><EditIcon /></Button>
            <Button onClick={deleteLink}><HighlightOffIcon /></Button>
        </ButtonGroup>
    )
}

export default BtngroupEditDelete