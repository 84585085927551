import React, {useEffect, useState} from 'react';
import {Routes, Route, Link} from 'react-router-dom';

import Layout from './layouts/index';
import MainPage from "./pages/main-page";
import MPList from "./pages/MP";
import EditMP from "./pages/edit-MP";
import AddMP from "./pages/add-MP";
import Schedule from "./pages/schedule";
import Settings from "./pages/settings";
import NotFoundPage from "./pages/not-found-page";

import ChooseChanelModal from "./modules/choose-chanel-modal";
import Test from "./pages/test";
import LoginPage from "./pages/login-page";
import TickerPage from "./pages/ticker-page";
import BannerPage from "./pages/banner-page";
import AddBanner from "./pages/add-Banner";
import AddTicker from "./pages/add-Ticker/index.";
import EditBanner from "./pages/edit-Banner";
import EditTicker from "./pages/edit-ticker";
import {useDispatch, useSelector} from "react-redux";
import {changeAdmin} from "./store/role";
import {changeGuest} from "./store/role";
import {changeDirector} from "./store/role";
import {changeOperator} from "./store/role";
import {changeManager} from "./store/role";
import UsersSettings from "./pages/users-settings";
import {roleById} from "./API/api";
import WatchPage from "./pages/watch-page";
import ExcelParse from "./pages/excel-parse";
import FileList from "./pages/file-list/FileList";
// import { Cube } from "react-preloaders2";

function App() {
  const { userRole } = useSelector((store) => store.userRole)
  const { isAdmin } = useSelector((store) => store.isAdmin)
  const { isDirector } = useSelector((store) => store.isDirector)
  const { isOperator } = useSelector((store) => store.isOperator)
  const { isManager } = useSelector((store) => store.isManager)
  const { isGuest } = useSelector((store) => store.isGuest)
  const accessToken = localStorage.getItem("access_token")
  const dispatch = useDispatch()

  //   const getRoleById = async () => {
  //     let result = ''
  //     if (userRole !== "GUEST") {
  //       const role = await roleById(accessToken, userRole)
  //       result = role.value
  //     }
  //     return result
  //   }
  // console.log(getRoleById())
  //   console.log(userRole)

  const checkRoles = () => {
        if (userRole === "ADMIN") {
          dispatch(changeAdmin(true))
          dispatch(changeGuest(false))
        }
        if (userRole === "DIRECTOR") {
          dispatch(changeDirector(true))
          dispatch(changeGuest(false))
        }
        if (userRole === "OPERATOR") {
          dispatch(changeOperator(true))
          dispatch(changeGuest(false))
        }
        if (userRole === "MANAGER") {
          dispatch(changeManager(true))
          dispatch(changeGuest(false))
        }
      }

    useEffect(() => {
      checkRoles()
        // getRoleById()
    }, [userRole])

  return (
    <>
      <Routes>
        <Route path='/' element={< Layout />}>
          <Route index element={<MainPage />} />
          <Route path='mp' element={isGuest ? <NotFoundPage /> : <MPList />} />
          <Route path='mp/editMP/:id' element={isGuest ? <NotFoundPage /> : <EditMP />} />
          <Route path='mp/addMP' element={isGuest ? <NotFoundPage /> : <AddMP />} />
          <Route path='schedule' element={isGuest ? <NotFoundPage /> : <Schedule />} />
          <Route path='banner' element={isGuest ? <NotFoundPage /> : <BannerPage />} />
          <Route path='banner/addBanner' element={isGuest ? <NotFoundPage /> : <AddBanner />} />
          <Route path='banner/editBanner/:id' element={isGuest ? <NotFoundPage /> : <EditBanner />} />
          <Route path='ticker' element={isGuest ? <NotFoundPage /> : <TickerPage />} />
          <Route path='ticker/addTicker' element={isGuest ? <NotFoundPage /> : <AddTicker />} />
          <Route path='ticker/editTicker/:id' element={isGuest ? <NotFoundPage /> : <EditTicker />} />
          <Route path='list/:folder' element={isGuest ? <NotFoundPage /> : <FileList />} />
          <Route path='settings' element={isGuest ? <NotFoundPage /> : <Settings />} />
          <Route path='excel-parse' element={isGuest ? <NotFoundPage /> : <ExcelParse />} />
          <Route path='watch' element={isGuest ? <NotFoundPage /> : <WatchPage />} />
          {(isAdmin || isDirector) && <Route path='users-settings' element={<UsersSettings />} />}

          <Route path='test' element={<Test />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
      {/* Modal Choose Lang*/}
        <ChooseChanelModal />
      {/*<Cube />*/}
      {/* End modal Choose Lang*/}
    </>
  );
}

export default App;
