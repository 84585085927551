import "./style.css"
import {useState} from "react";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

const SetpositionMplist = ({id, positionValue, getMpList, updatePosition}) => {
    const [value, setValue] = useState(positionValue)
    const positionSet = async (index, position) => {
        await updatePosition(localStorage.getItem("access_token"),
            id, {position: position})
        await getMpList()
    }
    return (
        <div className="set-position">
            <input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            />
            <DoneOutlineIcon
                className={"label-brand_inputposition_btn"}
                onClick={() => positionSet(id, value)}
                style={
                    (positionValue !== value) ? {color: "#1976d2"} : {}
                }
            />
        </div>
    )
}

export default SetpositionMplist