import './style.css'
import {useEffect, useState} from "react";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useSelector} from "react-redux";

const StartEndDate = ({setSelectDates, selectDates}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [firstDate, setFirstDate] = useState('');
    const [lastDate, setLastDate] = useState('');
    const {activeMonth} = useSelector((store) => store.month);
    const [selectedMonth, setSelectedMonth] = useState()
    const [selectedYear, setSelectedYear] = useState()
    const [arrAvailableDate, setArrAvailableDate] = useState([])

    useEffect(() => {
        setSelectedMonth(new Date(activeMonth).getMonth())
        setSelectedYear(new Date(activeMonth).getFullYear())
        setStartDate()
        setEndDate()
    }, [activeMonth])

    const defineFirstLastDate = () => {
            setLastDate(new Date(new Date(selectedYear,selectedMonth + 1, 0)).getDate())
            if (
                selectedMonth === new Date().getMonth() &&
                selectedYear === new Date().getFullYear()
            ) {
                setFirstDate(new Date(activeMonth).getDate())
            } else {setFirstDate(1)}
    }

    const addArrAvailableDate = () => {
        let arr = []
        for (let i = firstDate; i <= lastDate; i++) {
            arr.push(i)
        }
        setArrAvailableDate(arr)
    }

    useEffect(() => {
            defineFirstLastDate()
    },[activeMonth, selectedMonth, selectedYear])

    useEffect(() => {
        addArrAvailableDate()
    }, [firstDate, lastDate])

    const handleChangeStart = (event) => {
        setStartDate(event.target.value);
    };
    const handleChangeEnd = (event) => {
        setEndDate(event.target.value);
    };

    // Добавление дней выхода
    const addSelectDates = () => {
        for (let i = startDate; i <= endDate; i++) {
            if (
                selectDates.length > 0 &&
                !selectDates.includes(i)
            ) {
                // console.log(!selectDates.includes(i))
                // console.log(i)
                setSelectDates(oldArray => [...oldArray, i])
                }
            if (
                selectDates.length === 0
            ) {
                setSelectDates(oldArray => [...oldArray, i])
            }
        }
    }

    // Убрать диапазон дней выхода
    const deleteSelectDates = () => {
        let arr = selectDates
        for (let i = startDate; i <= endDate; i++) {
            if (
                arr.length > 0 &&
                arr.includes(i)
            ) {
                // console.log(i)
                arr = arr.filter(sDay => sDay != i)
                // setSelectDates(oldArray => [...oldArray, i])
            }
            // if (
            //     selectDates.length === 0
            // ) {
            //     setSelectDates(oldArray => [...oldArray, i])
            // }
        }
        // console.log(arr)
        setSelectDates(arr)
    }


    // console.log(firstDate)
    // console.log(endDate)
    // console.log(arrAvailableDate)

    return (
        <div className="start-end-date">
            <FormControl fullWidth>
                <InputLabel id="demo-select-small">Начало</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={startDate ? startDate : ""}
                    label="Начало"
                    onChange={handleChangeStart}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {arrAvailableDate ? arrAvailableDate.map((el) => (
                        <MenuItem key={el} value={el}>{`${el}`}</MenuItem>
                        )): ""}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="demo-select-small">Конец</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={endDate ? endDate : ""}
                    label="Конец"
                    onChange={handleChangeEnd}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {arrAvailableDate ? arrAvailableDate.map((el) => (
                        <MenuItem key={el} value={el}>{`${el}`}</MenuItem>
                    )) : ""}
                </Select>
            </FormControl>
            <Button variant="contained" onClick={() => addSelectDates()}>Добавить</Button>
            <Button variant="outlined" onClick={() => deleteSelectDates()}>Убрать</Button>
        </div>

    );
}

export default StartEndDate