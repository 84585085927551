import './style.css'
import {Box, Button, MenuItem, Modal, TextField} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {useEffect, useState} from "react";
import {addUser, setUpdateUser} from "../../API/api";
import {useForm} from "react-hook-form";

const AddEditUser = ({data = null, openUserNodal, setOpenUserNodal, roles }) => {
    const accessToken = localStorage.getItem("access_token")
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userRole, setUserRole] = useState("GUEST")

    const {
        formState: {errors}
    } = useForm();

    const genPassword = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const passwordLength = 12
        let password = ""
        for (let i = 0; i <= passwordLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }
        return password
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const emailFildsCheck = () => {
        let result = false
        if (userEmail) {
            if (userEmail.indexOf('@') !== -1) {
                const arr = userEmail.split('@')
                if (arr[1] !== 'gmail.com') {
                    result = true
                }
            }
            if (userEmail.indexOf('@') === -1) {
                result = true
            }
        }
        return result
    }

    const createUser = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            let data2 = {}
            data2.name = userName
            data2.email = userEmail
            data2.password = genPassword()
            data2.role = userRole
            const result = await addUser(accessToken, data2)
            if (result) {
                setOpenUserNodal(false)
                // getUsers()
                setUserName('')
                setUserEmail('')
                setUserRole('')
            }
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }

    }

    const updateUser = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            let data2 = {}
            data2.name = userName
            data2.email = userEmail
            data2.role = userRole
            const result = await setUpdateUser(accessToken, data.id, data2)
            // console.log(data)
            if (result) {
                setOpenUserNodal(false)
                // getUsers()
                setUserName('')
                setUserEmail('')
                setUserRole('')
            }
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }

    }

    useEffect(() => {
        if (data) {
            setUserName(data.name)
            setUserEmail(data.email)
            setUserRole(data.role)
        }
        if (!data) {
            setUserName('')
            setUserEmail('')
            setUserRole('')
        }
    },[data])

    return (
        <Modal
            open={openUserNodal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="add-user-form">
                    <TextField
                        id="outlined-basic"
                        label="Имя"
                        variant="outlined"
                        className="add-user-form_input"
                        value={userName}
                        onChange={(e) => {
                            setUserName(e.target.value);
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        className="add-user-form_input"
                        type={"email"}
                        value={userEmail}
                        onChange={(e) => {
                            setUserEmail(e.target.value);
                        }}
                        error={emailFildsCheck()}
                        helperText={emailFildsCheck() ? "Почта должна быть Gmail.com!" : ""}
                    />
                    <TextField
                        id="outlined-select-currency"
                        className="add-user-form_input"
                        select
                        label="Группа"
                        // defaultValue="GUEST"
                        helperText="Выберите группу пользователя"
                        value={userRole}
                        onChange={(e) => {
                            setUserRole(e.target.value);
                        }}
                    >
                        {roles?.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                                {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className="mp-brand-form-clips_btn">
                        {!data ? <Button variant="contained" onClick={() => createUser()}>Добавить пользователя</Button> :
                        <Button variant="contained" onClick={() => updateUser()}>Обновить пользователя</Button>}
                    </div>
                    <div className="add-user-form_btnclose" onClick={() => setOpenUserNodal(false)}><HighlightOffIcon /></div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddEditUser