import "./style.css"
import BtngroupEditDelete from "../../components/btngroup-edit-delete";
import MonthSlider from "../../modules/month-slider";
import LabelBrand from "../../components/label-brand";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {deleteMpTable, getAllChanels, getMonthMP, setActiveMP, setUpdatePosition} from "../../API/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ConfirmModal from "../../components/confirm-modal";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import SetpositionMplist from "../../modules/setposition-mplist";

const MPList = () => {
    const {activeMonth} = useSelector((store) => store.month)
    const {activeChanel} = useSelector((store) => store.chanel)
    const { isAdmin } = useSelector((store) => store.isAdmin)
    const { isOperator } = useSelector((store) => store.isOperator)
    const [mpStatus, setMpStatus] = useState()
    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const navigate = useNavigate();

    const month = monthName(activeMonth)

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
        if (isAdmin || isOperator) {setSortNumber(3)}
    },[]);

    const [mpList, setMpList] = useState([])
    const [sortNumber, setSortNumber] = useState(1)
    const getMpList = async () => {
        if (chanels.length > 0) {
            const data = await getMonthMP(localStorage.getItem("access_token"),
                `${month}`, `${chanels[activeChanel]?.id}`);
            if (sortNumber === 1) {
                data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                })
            }
            if (sortNumber === 2) {
                data.sort((a, b) => a.id - b.id)
            }
            if (sortNumber === 3) {
                data.sort((a, b) => a.position - b.position)
            }
            setMpList(data)
        }
    }
    useEffect(() => {
        getMpList();
    },[chanels, activeMonth, activeChanel, mpStatus, sortNumber]);

    const deleteMp = async (id) => {
        await deleteMpTable(localStorage.getItem("access_token"), id)
        getMpList()
    }

    const inWork =  async (id, status) => {
        try {
            setMpStatus(null)
            await setActiveMP(localStorage.getItem("access_token"), id, {status: !status})
            setMpStatus(!status)
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    // Confirm Modal
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('')

    const ConfirmModalOpen = (id) => {
        setOpenConfirmModal(true);
        setDeleteId(id)
    };

    //Change Position
    const positionUp = async (index) => {
        await setUpdatePosition(localStorage.getItem("access_token"),
            mpList?.[index].id, {position: mpList?.[index - 1].position})
        await setUpdatePosition(localStorage.getItem("access_token"),
            mpList?.[index - 1].id, {position: mpList?.[index]?.position})
        getMpList();
    }
    const positionDown = async (index) => {
        await setUpdatePosition(localStorage.getItem("access_token"),
            mpList?.[index].id, {position: mpList?.[index + 1].position})
        await setUpdatePosition(localStorage.getItem("access_token"),
            mpList?.[index + 1].id, {position: mpList?.[index]?.position})
        getMpList();
    }

    return (
        <div className="MP-list container">
            <MonthSlider />
            <div className="all-mp-list">
                <span>Список активных медиапланов:</span>
                <ul className="mp-sort-menu">
                    <li
                        style={sortNumber === 1 ? {fontWeight:700}: {}}
                        onClick={() => setSortNumber(1)}
                    >По алфавиту</li>
                    <li
                        style={sortNumber === 2 ? {fontWeight:700}: {}}
                        onClick={() => setSortNumber(2)}
                    >По дате добавление</li>
                    <li
                        style={sortNumber === 3 ? {fontWeight:700}: {}}
                        onClick={() => setSortNumber(3)}
                    >По позиции</li>
                </ul>
                {mpList?.map((mp, index) =>
                    (
                        <div key={mp.id} className={"brand-name-list"}>
                            <div style={sortNumber === 3 ? {display:"flex",flexDirection:"column", gap:3}: {display:"none"}}>
                                <ArrowUpward
                                    style={{
                                        color:"#1565c0",
                                        width:15,
                                        fontSize:null,
                                        cursor:"pointer",
                                        display: (index === 0) ? "none": "inline-block"
                                    }}
                                    onClick={() => positionUp(index)}
                                />
                                <ArrowDownward
                                    style={{
                                        color:"#1565c0",
                                        width:15,
                                        fontSize:null,
                                        cursor:"pointer",
                                        display: (index === mpList.length - 1) ? "none": "inline-block"
                                    }}
                                    onClick={() => positionDown(index)}
                                />
                            </div>
                            <div style={sortNumber === 3 ? {} : {display:"none"}}>
                                <SetpositionMplist
                                    id={mp.id}
                                    positionValue={mp.position}
                                    getMpList={getMpList}
                                    updatePosition={setUpdatePosition}
                                />
                            </div>
                            <LabelBrand brandName={mp.name} />
                            <BtngroupEditDelete editLink={() => navigate(`/mp/editMP/${mp.id}`)} deleteLink={() => ConfirmModalOpen(mp.id)} />
                            {/*<div className="label-brand" style={{minWidth:50}}>{mp.position} </div>*/}
                            <div className={mp.status ? "mp-active-circle" : "mp-notActive-circle"} onClick={() => inWork(mp.id, mp.status)}></div>
                        </div>
                    )
                )}
            </div>
            <div className="btn-add-mp">
                <Button variant="contained" onClick={() => navigate('/mp/addMP')}>Добавить МП</Button>
            </div>
            <ConfirmModal openConfirmModal={openConfirmModal} setOpenConfirmModal={setOpenConfirmModal} link={() => deleteMp(deleteId)} />
        </div>
    )
}

export default MPList