import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //activeChanel: "Choose",
    activeChanel: localStorage.getItem("activeChanel") || "Choose"
};

export const chanelSlice = createSlice({
    name: "chanel",
    initialState,
    reducers: {
        changeChanel: (state, action) => {
            state.activeChanel = action.payload;
            localStorage.setItem("activeChanel", action.payload);
        },
    },
});
// Action creators are generated for each case reducer function
export const { changeChanel } = chanelSlice.actions;

export default chanelSlice.reducer;
