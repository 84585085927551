import "./style.css"
import {useEffect, useState} from "react";
import {
    addRole,
    addUser,
    allRoles,
    allUsers,
    deleteRole,
    deleteUser,
    getAllChanels,
    loginUser,
    roleById
} from "../../API/api";
import BtngroupEditDelete from "../../components/btngroup-edit-delete";
import LabelBrand from "../../components/label-brand";
import {useForm} from "react-hook-form";
import {Box, Button, Input, MenuItem, Modal, TextField} from "@mui/material";
import AddEditUser from "../../modules/add-edit-user";
import AddEditRole from "../../modules/add-edit-role";

const UsersSettings = () => {
    const accessToken = localStorage.getItem("access_token")
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [openUserNodal, setOpenUserNodal] = useState(false);
    const [openRoleNodal, setOpenRoleNodal] = useState(false);
    const {
        formState: {errors}
    } = useForm();
    const getRoles = async () => {
        const data = await allRoles(accessToken)
        setRoles(data)
    }
    const getUsers = async () => {
        const data = await allUsers(accessToken)
        setUsers(data)
    }

    useEffect(() => {
        getRoles()
        getUsers()
    },[openUserNodal, openRoleNodal]);

    const [dataUser, setDataUser] = useState(null)
    const [dataRole, setDataRole] = useState(null)
    const addUser = () => {
        setDataUser(null)
        setOpenUserNodal(true)
    }
    const editUser = (data) => {
        setDataUser(data)
        setOpenUserNodal(true)
    }
    const deleteUserBtn = async (id) => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            await deleteUser(accessToken, id)
            await getUsers()
        } catch (error) {
        console.dir(error);
        throw new Error("Что-то не так с пост запросом");
        }
    }
    const addRole = () => {
        setDataRole(null)
        setOpenRoleNodal(true)
    }
    const editRole = (data) => {
        setDataRole(data)
        setOpenRoleNodal(true)
    }
    const deleteRoleBtn = async (id) => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            await deleteRole(accessToken, id)
            await getRoles()
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    return (
        <div className="users-settings_page container">
            <p>Пользователи:</p>
            <div>
                {users?.map((user, index) => (
                    <div key={user.id} className={"brand-name-list"}>
                        <p>{index +1 }</p>
                        <LabelBrand brandName={user.name} />
                        <LabelBrand brandName={user.email} />
                        <LabelBrand brandName={user.role} />
                        <BtngroupEditDelete
                            editLink={() => editUser(user)}
                            deleteLink={() => deleteUserBtn(user.id)}
                        />
                    </div>
                ))}
                <Button style={{width:280}} variant="contained" onClick={() => addUser()}>Добавить пользователя</Button>
            </div>
            <p>Группы:</p>
            <div>

                {roles?.map((role, index) => (
                    <div key={role.id} className={"brand-name-list"}>
                        <p>{index +1 }</p>
                        <LabelBrand brandName={role.description} />
                        <BtngroupEditDelete
                            editLink={() => editRole(role)}
                            deleteLink={() => deleteRoleBtn(role.id)}
                        />
                    </div>
                ))}
                <Button style={{width:280}} variant="contained" onClick={() => addRole()}>Добавить группу</Button>
            </div>

            {/* MODAL ADD USER */}
            <AddEditUser data={dataUser} setOpenUserNodal={setOpenUserNodal}
                         openUserNodal={openUserNodal} roles={roles} />
            {/* MODAL ADD GROUP*/}
            <AddEditRole data={dataRole} setOpenRoleNodal={setOpenRoleNodal} openRoleNodal={openRoleNodal} />
        </div>
    )
}

export default UsersSettings