import './style.css'
import MonthSlider from "../../modules/month-slider";
import {Button, TextareaAutosize} from "@mui/material";
import ConfirmModal from "../../components/confirm-modal";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    deleteBanner,
    getAllChanels,
    getDayBanners, getDayBannersText,
    getMonthBanners, setUpdateBannerFile, setUpdatePositionBanners
} from "../../API/api";
import LabelBrand from "../../components/label-brand";
import BtngroupEditDelete from "../../components/btngroup-edit-delete";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import SetpositionMplist from "../../modules/setposition-mplist";

const BannerPage = () => {
    const {activeMonth} = useSelector((store) => store.month);
    const {activeChanel} = useSelector((store) => store.chanel);
    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const navigate = useNavigate();

    const month = monthName(activeMonth)

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    //Проверить для отображения дней скопировал.
    const [dayPerMonth, setDayPerMonth] = useState([])
    const [currentDay, setCurrentDay] = useState(null)
    const currentDays = () => {
        setDayPerMonth([])
        const lastDayMonth = new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate()
        for (let i = 1; i <= lastDayMonth; i++) {
            setDayPerMonth(oldArray => [...oldArray, i])
        }
    }
    useEffect(() => {
        currentDays()
    }, [activeMonth])

    //Получаем дынные
    const [bannerList, setBannerList] = useState([])
    //Месяц
    const getBannerList = async () => {
        if (chanels.length > 0) {
            if (!currentDay) {
                const data = await getMonthBanners(localStorage.getItem("access_token"),
                    `${month}`, `${chanels[activeChanel]?.id}`)
                setBannerList(data)
            }
            if (currentDay) {
                const data = await getDayBanners(localStorage.getItem("access_token"),
                    `${month}`, `${chanels[activeChanel]?.id}`, `${currentDay}`)
                setBannerList(data)
            }
        }
    }
    useEffect(() => {
        getBannerList();
    },[chanels, activeMonth, activeChanel, currentDay]);

    const deleteSelectBanner = async (id) => {
        await deleteBanner(localStorage.getItem("access_token"),id)
        getBannerList()
    }

    // Confirm Modal
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('')

    const ConfirmModalOpen = (id) => {
        setOpenConfirmModal(true);
        setDeleteId(id)
    };

    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }

    // Проверка получения текста бегушки:
    const [bannersTextList, setBannersTextList] = useState([])
    //Месяц
    const getBannersTextList = async () => {
        if (chanels.length > 0) {
            const data = await getDayBannersText(
                localStorage.getItem("access_token"),
                `${month}`,
                `${chanels[activeChanel]?.id}`,
                `${currentDay}`
            )
            setBannersTextList(data)
        }
    }
    useEffect(() => {
        getBannersTextList();
    },[chanels, activeMonth, activeChanel, currentDay]);

    const updateBannerFile = async () => {
        try {
            await setUpdateBannerFile(localStorage.getItem("access_token"), chanels[activeChanel]?.id)
            alert(`Файл банеров успешно обновлен!`)
            // navigate('/ticker')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    //Change Position
    const positionUp = async (index) => {
        await setUpdatePositionBanners(localStorage.getItem("access_token"),
            bannerList?.[index].id, {position: bannerList?.[index - 1].position})
        await setUpdatePositionBanners(localStorage.getItem("access_token"),
            bannerList?.[index - 1].id, {position: bannerList?.[index]?.position})
        await getBannerList();
    }
    const positionDown = async (index) => {
        await setUpdatePositionBanners(localStorage.getItem("access_token"),
            bannerList?.[index].id, {position: bannerList?.[index + 1].position})
        await setUpdatePositionBanners(localStorage.getItem("access_token"),
            bannerList?.[index + 1].id, {position: bannerList?.[index]?.position})
        await getBannerList();
    }

    //Функция подсветки закончившихся баннеров
    const setColor = (lastDay) => {
        if (month === monthName(new Date())) {
            if (new Date().getDate() === lastDay) {
                return 'blue'
            }
            if (new Date().getDate() + 1 === lastDay) {
                return 'yellow'
            }
            if (new Date().getDate() > lastDay) {
                return 'red'
            }
            else {return ''}
        }
    }

    return (
        <div className="banner-page container">
            <MonthSlider />
            <div className="pagination-list">
                <Button variant="contained" onClick={() => setCurrentDay(null)}>Весь МЕСЯЦ</Button>
            </div>
            <div className="pagination-list">{dayPerMonth.map((el) =>
                <p key={el} className={el === currentDay ? "active-pagination" :
                    `${getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "СБ" ||
                    getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "ВС" ? "weekend" : ""}`
                }
                   onClick={() => setCurrentDay(el)}
                >
                    {el}
                </p>)}
            </div>
            <div className="pagination-list" style={{marginTop:"-33px"}}>{dayPerMonth.map((el) =>
                <p key={el} className={el === currentDay ? "active-pagination" :
                    `${getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "СБ" ||
                    getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "ВС" ? "weekend" : ""}`
                }
                   onClick={() => setCurrentDay(el)}
                >
                    {getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el))}
                </p>)}
            </div>
            <div className="all-mp-list">
                <span>Список активных Banner:</span>
                {bannerList?.map((banner, index) =>
                    (
                        <div key={banner.id} className={"brand-name-list"}>
                            <div style={{display:"flex",flexDirection:"column", gap:3}}>
                                <ArrowUpward
                                    style={{
                                        color:"#1565c0",
                                        width:15,
                                        fontSize:null,
                                        cursor:"pointer",
                                        display: (index === 0) ? "none": "inline-block"
                                    }}
                                    onClick={() => positionUp(index)}
                                />
                                <ArrowDownward
                                    style={{
                                        color:"#1565c0",
                                        width:15,
                                        fontSize:null,
                                        cursor:"pointer",
                                        display: (index === bannerList.length - 1) ? "none": "inline-block"
                                    }}
                                    onClick={() => positionDown(index)}
                                />
                            </div>
                            <div>
                                <SetpositionMplist
                                    id={banner.id}
                                    positionValue={banner.position}
                                    getMpList={getBannerList}
                                    updatePosition={setUpdatePositionBanners}
                                />
                            </div>
                            <LabelBrand
                                brandName={banner.name}
                                color={setColor(banner.days.at(-1))}
                            />
                            <BtngroupEditDelete
                                editLink={() => navigate(`/banner/editBanner/${banner.id}`)}
                                deleteLink={() => ConfirmModalOpen(banner.id)}
                            />
                            <span>До {banner.days.at(-1)} {month}</span>
                        </div>
                    )
                )}
            </div>
            <div className="btn-add-mp">
                <Button variant="contained" onClick={() => navigate('/banner/addBanner')}>Добавить Банер</Button>
                <Button variant="contained" onClick={() => navigate('/list/bannerUrl')}>Список всех Банеров</Button>
                <Button variant="outlined" onClick={() => updateBannerFile()}>Обновить файл</Button>
            </div>
            <div className="all-mp-list">
                {currentDay &&
                    <TextareaAutosize
                        className="ticker-textaria"
                        placeholder="Список баннеров"
                        maxRows={10}
                        value={bannersTextList}
                    />
                }
            </div>
            <ConfirmModal
                openConfirmModal={openConfirmModal}
                setOpenConfirmModal={setOpenConfirmModal}
                link={() => deleteSelectBanner(deleteId)}
            />
        </div>
    )
}

export default BannerPage