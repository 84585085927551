import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getDayRekBlock, getDayRekBlockWithoutID} from "../../API/api";
import {focus} from "@testing-library/user-event/dist/focus";
import {useForm} from "react-hook-form";
import './style.css'


const MptableInput = (
    {
        errTimeBlock,
        setErrTimeBlock,
        handlePaste1,
        handlePaste,
        elm,
        res,
        index,
        i,
        register,
        setRekBlockList,
        chanels,
        clips,
        dayName,
        watch,
        setDayName,
        setRekIndex,
        id = null
    }) => {

    const {activeChanel} = useSelector((store) => store.chanel);
    const {activeMonth} = useSelector((store) => store.month);
    const [selectedMonth, setSelectedMonth] = useState(new Date(activeMonth).getMonth())
    const [selectedYear, setSelectedYear] = useState(new Date(activeMonth).getFullYear())
    const [timeRek, setTimeRek] = useState([])
    const [clipTime, setClipTime] = useState(null)
    const [resultTime, setResultTime] = useState()
    const [inputValue, setInputValue] = useState()
    const { isAdmin } = useSelector((store) => store.isAdmin)
    const { isOperator } = useSelector((store) => store.isOperator)

    // console.log(dayName)
    // const { setValue } = useForm();


    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)
    const getRekBlockList = async (day, block) => {
        setTimeRek([])
        if (id) {
            const data = await getDayRekBlockWithoutID(
                localStorage.getItem("access_token"),
                `${month}`, `${chanels[activeChanel]?.id}`,
                `${day}`,`${block}`,`${id}`)
            setRekBlockList(data)
            await setTimeRek(data)
        }
        if (!id) {
            const data = await getDayRekBlock(localStorage.getItem("access_token"),
                `${month}`, `${chanels[activeChanel]?.id}`, `${day}`,`${block}`)
            setRekBlockList(data)
            await setTimeRek(data)
        }
    }

    // console.log(Object.values(chanels[activeChanel]?.timeBlock?.[dayName]?.[index])[0])
    const blockList = () => {
        const arr = []
        if (timeRek.length > 0) {
            timeRek?.map((el) => {
                const data = Object.values(el)
                if (data.length > 0) {
                    arr.push(Number(data[0].length))
                }
            })
        }


        const result1 = arr?.length > 0 ? arr?.reduce((a, b) => a + b) : ""
        // const result = 180 - result1
        const result = Object.values(chanels[activeChanel]?.timeBlock?.[dayName]?.[index])[0] - result1
        // console.log(result)
        return result
        // setDataTime(result)
    }

    // 111111
    const getClipTime = (e) => {
        let res = 0
        clips?.map((el) => {
            if (el.short.toLowerCase() === e.target.value.toLowerCase()) {
                res = Number(el.length)
            }
                })
        setClipTime(res)
        // const  result = blockList() - res

        // console.log(result)
        // console.log(e.target.value)
    }
    const onClick = async (e) => {
        await getRekBlockList(`${elm}`,`${res}`)
        await getClipTime(e)
        setDayName(dayName)
        setRekIndex(index)
        // await blockList()
        // setDataTime(a)
        // console.log(dataTime)
    }

    const timeValidation = () => {
        if (clipTime) {
            if (resultTime >= 0) {
                return ("lawngreen")
            }
            else {
                if (errTimeBlock &&
                    !errTimeBlock.includes(`${elm} ${res}`)) {
                    setErrTimeBlock(oldArray => [...oldArray, `${elm} ${res}`])
                }
                return ("palevioletred")
            }
        }

    }

    useEffect(() => {
        if (clipTime) {
            const a = blockList()
            const  result = a - clipTime
            setResultTime(result)
            // console.log(result)
        }

    },[timeRek, clipTime])

    useEffect(() => {
        setSelectedMonth(new Date(activeMonth).getMonth())
        setSelectedYear(new Date(activeMonth).getFullYear())
    }, [activeMonth])

    // console.log(inputValue)

    // Read Only ограничение редактирования дней
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[new Date(date).getDay()];
    }
    const readOnlyChk = () => {
        if (isAdmin) {return false}
        if (isOperator) {
            return readOnlyDateCheckOperator(elm)
        } else {
            return readOnlyDateCheck(elm)
        }

    }
    const readOnlyDateCheckOperator = (elm) => {
        const month = new Date().getMonth()
        const year = new Date().getFullYear()

        let result = false

        //Закрыть преведущие месяцы
        if (
            selectedMonth < month &&
            selectedYear <= year
        ) {result = true}

        // Проверка текущий меясяц
        if (
            selectedMonth === month &&
            selectedYear === year
        ) {
            // Закрыть все преведущие дни текущий месяц
            if (
                elm < new Date().getDate()
            ) {
                result = true
            }
        }
        return(result)

    }
    const readOnlyDateCheck = (elm) => {
        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const nameDayToday = getWeekDay(new Date())
        const sexteenEnd = new Date().setMilliseconds(8 * 60 * 60 * 1000)

        const lastDayMonthNumber = (year, month) => {
            return new Date(year,month + 1, 0)
        }
        const firstDayMonthName = (year, month) => {
            return getWeekDay(new Date(year,month, 1))
        }

        let result = false

        //Закрыть преведущие месяцы
        if (
            selectedMonth < month &&
            selectedYear <= year
        ) {result = true}
        // Проверка текущий меясяц
        if (
            selectedMonth === month &&
            selectedYear === year
        ) {
            // Закрыть все преведущие дни текущий месяц
            if (
                elm <= new Date(sexteenEnd).getDate()
            ) {result = true}
            if (
                new Date().getDate() === new Date(lastDayMonthNumber(year, month)).getDate() &&
                new Date().getHours() >= 16
            ) {result = true}
            // Если пятница закрыть выходные и понедельник
            if (
                nameDayToday === "ПТ" &&
                getWeekDay(new Date(sexteenEnd)) === "СБ" &&
                elm <= ((new Date(sexteenEnd).getDate()) + 2)
            ) {result = true}
            // если суббота
            if (
                nameDayToday === "СБ" &&
                elm <= ((new Date().getDate()) + 2)
            ) {result = true}
            // если воскресение
            if (
                nameDayToday === "ВС" &&
                elm <= ((new Date().getDate()) + 1)
            ) {result = true}
        }
        // Если выбран следуюзий месяц
        if (
            selectedMonth === (month + 1) &&
            selectedYear === year
        ) {
            //Если последний день
            if (
                new Date().getDate() === new Date(lastDayMonthNumber(year, month)).getDate()
                // elm === 1
            ) {
                // Закрываем 1-ое число нового месяца
                if (
                    new Date().getHours() >= 16 &&
                    elm === 1
                ) {result = true}
                // Если первое суббота
                if (
                    getWeekDay(new Date(sexteenEnd)) === "СБ" &&
                    elm <= 3
                ) {result = true}
            }

            // Если первое воскресение срез оставшихся дней месяца
            if (
                firstDayMonthName(selectedYear, selectedMonth) === "ВС" &&
                new Date().getDate() >= new Date(lastDayMonthNumber(year, month)).getDate() -1 &&
                new Date().getHours() >= 16 &&
                elm <= 2
            ) {

                result = true
            }
            // Если первое понедельник срез оставшихся дней месяца
            if (
                firstDayMonthName(selectedYear, selectedMonth) === "ПН" &&
                new Date().getDate() >= new Date(lastDayMonthNumber(year, month)).getDate() -2 &&
                new Date().getHours() >= 16 &&
                elm === 1
            ) {result = true}
        }

        return(result)
    }

    // console.log(watch(`reklama.${elm}.${res}`))

    return (
        // <td
        //     key={key}
        //     style={{
        //         width: "22px",
        //         minHeight: "24px",
        //         border: "1px solid black",
        //         borderRadius: "0px",
        //         wordWrap: "break-word",
        //     }}
        // >
        readOnlyChk() ?
            <div
                style={{
                    width: "20px",
                    minHeight: "24px",
                    maxHeight: "24px",
                    border: "1px solid black",
                    borderRadius: "0px",
                    wordWrap: "break-word",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className={`${dayName === "СБ" || dayName === "ВС" ? "weekend" : ""} mp-table_input_text`}
                {...register(`reklama.${elm}.${res}`, {
                    max: 1,
                    // validate: () => `${timeValidation()}` === "lawngreen" || "Ошибка блок превышен"
                })}
            >
                {watch(`reklama.${elm}.${res}`)}
            </div>
            :
            <input
                style={{
                    width: "20px",
                    minHeight: "24px",
                    maxHeight: "24px",
                    border: "1px solid black",
                    borderRadius: "0px",
                    wordWrap: "break-word",
                    background: `${timeValidation()}`,
                }}
                onInput={(e) => {
                    handlePaste1(e, elm, res);
                    onClick(e);
                    // setValue(`reklama.${elm}.${res}`, `${e.target.value}`, { shouldTouch: true });
                    // console.log(`reklama.${elm}.${res} ${e.target.value}`)
                }}
                onPaste={(e) => {
                    handlePaste(index, elm, e, i);
                    // console.log(e.target.value)
                    // setValue(`reklama.${elm}.${res}`, `${e.target.value}`, { shouldTouch: true });
                }}
                type="textbox"
                maxLength="1"
                className={`${dayName === "СБ" || dayName === "ВС" ? "weekend" : ""} mp-table_input_text`}
                onClick={(e) => onClick(e)}
                onFocus={(e) => onClick(e)}
                // value={inputValue}
                // onChange={(e) => {
                //     setInputValue(e.target.value);
                // }}
                // onChange={(e) => onClick(e)}
                // disabled={elm <= new Date(new Date().setMilliseconds(8 * 60 * 60 * 1000)).getDate()}
                // onError={timeValidation() === "lawngreen"}
                readOnly={readOnlyChk()}
                {...register(`reklama.${elm}.${res}`, {
                    max: 1,
                    // validate: () => `${timeValidation()}` === "lawngreen" || "Ошибка блок превышен"
                })}
            />

        // </td>
    )
}

export default MptableInput