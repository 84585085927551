import "./style.css"
import {useSelector} from "react-redux";
import MpBrand from "../../modules/mp-brand";
import {useEffect, useState} from "react";
import MpTable from "../../modules/mp-table";
import {addNewMP, getAllChanels} from "../../API/api";
import {Button, Input} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

const AddMP = () => {
    const {activeMonth} = useSelector((store) => store.month);
    const {activeChanel} = useSelector((store) => store.chanel);
    const { userName } = useSelector((store) => store.userName)

    const [errTimeBlock, setErrTimeBlock] = useState([])
    const [dayName, setDayName] = useState('ПН')
    const [rekIndex, setRekIndex] = useState(0)

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const navigate = useNavigate()

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        control,
        reset,
        watch,
    } = useForm();

    const [clips, setClips] = useState([]);
    const [currentData, setCurrentData] = useState({})
    const [test, setTest] = useState({})

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const [dayPerMonth, setDayPerMonth] = useState([])

    const currentDays = () => {
        setDayPerMonth([])
        const lastDayMonth = new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate()
        for (let i = 1; i <= lastDayMonth; i++) {
            setDayPerMonth(oldArray => [...oldArray, i])
        }
    }

    useEffect(() => {
        currentDays()
    }, [activeMonth])

    //Убераем пробелы из ячеек???
    const rekSpaceDelete = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            // Если элемент массива - объект
            if (typeof arr[i] === 'object' && arr[i] !== null) {
                // Проход по свойствам объекта
                for (let key in arr[i]) {
                    // Если свойство является строкой
                    if (typeof arr[i][key] === 'string') {
                        // Удаление пробелов из значения
                        arr[i][key] = arr[i][key].replace(/\s+/g, '');
                    }
                }
            }
        }
        return arr
    }

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            //await setClips(data.clips);
            data.chanel = chanels[activeChanel].id
            data.user = userName
            data.month = monthName(activeMonth)
            data.clips = clips
            data.status = false
            data.position = Number(data.position)
            let rek = data.reklama
            data.reklama = rekSpaceDelete(rek)
            ////let rek = data.reklama
            /////let rekClean = cleanObj(rek)
            // let rekClean = data.reklama
            // data.reklamaFull = addClipInRek(rekClean, data.clips)
            setCurrentData(data)
            // let testRek = addClipInRek(data.reklama, data.clips)
            // setTest(testRek)
            // console.log(data);
            await addNewMP(localStorage.getItem("access_token"),data);
            // setClipsField([]);
            // handleClose();
            navigate('/mp')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    // //Get Data Block
    const [rekBlockList, setRekBlockList] = useState([])
    const month = monthName(activeMonth)
    // console.log(rekBlockList)

    const blockList = () => {
        const arr = []
        rekBlockList?.map((el) => {
            const data = Object.values(el)
            if (data.length > 0) {
                arr.push(Number(data[0].length))
            }
        })

        const timeLimit = chanels[activeChanel]?.timeBlock?.[dayName]?.[rekIndex]
            ? Object.values(chanels[activeChanel]?.timeBlock?.[dayName]?.[rekIndex])[0]
            : 0
        let result1 = arr.length > 0 ? arr?.reduce((a, b) => a + b) : ""
        // let result = 180 - result1
        let result = timeLimit - result1
        return result
    }


    // Покраска блока
    const bgc = (time) => {
        if (time > 61) {
            return ""
        }
        if (46 <= time && time <= 60) {
            return "deepskyblue"
        }
        if (31 <= time && time <= 45) {
            return "green"
        }
        if (21 <= time && time <= 30) {
            return "yellow"
        }
        if (11 <= time && time <= 20) {
            return "orange"
        }
        if (1 <= time && time <= 10) {
            return "red"
        }
        if ( time <= 0) {
            return "black"
        }
    }



    return (
        <div className="addMP container">
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mp-head">
                <div className="mp-head-left">
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Телеканал:</div>
                        <div className="mp-head_text_right">{chanels[activeChanel]?.chanel}</div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Пользователь:</div>
                        <div className="mp-head_text_right">{userName}</div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Брэнд:</div>
                        <div className="mp-head_text_right">
                            <Input
                                className="mp-head_input"
                                placeholder="Название"
                                {...register("name",{
                                    required: true
                                })}
                            />
                        </div>
                    </div>
                    <div className="mp-head_text">
                        <div className="mp-head_text_left">Позиция:</div>
                        <div className="mp-head_text_right">
                            <Input
                                className="mp-head_input"
                                placeholder="Позиция"
                                defaultValue={"70"}
                                type={"number"}
                                {...register("position")}
                            />
                        </div>
                    </div>
                    <MpBrand
                        activeChanel={activeChanel}
                        activeMonth={activeMonth}
                        clips={clips}
                        setClips={setClips}
                        add={true}
                    />
                </div>
                <div className={`mp-head-right ${bgc(Number(blockList()))}`}>
                    <span>Ролики в блоке</span>
                    {rekBlockList?.map((el, i) => {
                        const data = Object.values(el)
                        if (data.length > 0) {
                            return (<p key={i}>{data[0].name} : {data[0].length}c</p>)
                        }
                    })}
                    <span>Осталось: {blockList()} сек</span>
                </div>
            </div>
            <MpTable
                chanels={chanels}
                dayPerMonth={dayPerMonth}
                register={register}
                setValue={setValue}
                setRekBlockList={setRekBlockList}
                clips={clips}
                setErrTimeBlock={setErrTimeBlock}
                errTimeBlock={errTimeBlock}
                watch={watch}
                setDayName={setDayName}
                setRekIndex={setRekIndex}
            />
            <div>
                <Button type={"submit"} variant="contained">Сохранить</Button>
                <Button variant="outlined" onClick={() => navigate(-1)}>Отмена</Button>
            </div>
            </form>
        </div>
    )
}

export default AddMP