import "./style.css"
import MonthChoose from "../month-choose";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FilledInput,
    Input
} from "@mui/material";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import BtngroupEditDelete from "../../components/btngroup-edit-delete";
import ModalAddEdit from "../../components/modal-add-edit";

const MpBrand = ({activeChanel, activeMonth, clips, setClips, data = false, add = false}) => {

    // Add Clips
    const [clipsField, setClipsField] = useState([]);

    const [activeClip, setActiveClip] = useState(null)

    useEffect(() => {
        if (data) {
            setClips(data.clips)
        }
    }, [])

    const addMoreClipsBtn = () => {
        setClipsField([...clipsField, (Number(clipsField) + 1)]);
    }
    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm();

    const deleteClips = (id) => {
        const newArr = clips.filter(n => n.id !== id)
        setClips(newArr);
    }



    // Modal Clips
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        //setActiveClip(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (clip) => {
        //setActiveClip(null)
        if (clip) {
            setActiveClip(clip);
        }
        setOpen(true);
    }

    const isClips = (data) => {
        if (data.length > 0) {
            return (data.map((clip, index) => (
                <div key={index} className="mp-brand-form-clips">
                    <div className="mp-brand-form-clip_long">{clip.name}</div>
                    <div className="mp-brand-form-clip_short">{clip.length}</div>
                    <div className="mp-brand-form-clip_short">{clip.short}</div>
                    <BtngroupEditDelete
                        editLink={() => handleEditOpen(clip)}
                        deleteLink={() => deleteClips(clip.id)}
                    />
                </div>
            )))
        } else {
            return (<p>NOT CLIPS</p>)
        }
    }




     return (
        <div className="mp-brand">
                {/*<Input disabled defaultValue={activeChanel} {...register(`month`)} />*/}
            <div className="mp-head_text">
                <div className="mp-head_text_left">Месяц:</div>
                <div className="mp-head_text_right">
                    {add ?
                        <MonthChoose /> :
                        <Input disabled defaultValue={activeMonth} {...register(`month`)}  />
                    }
                </div>
            </div>
            <div className="mp-brand_clips">
                {isClips(clips, true)}
            </div>
            <div className="mp-head_text">
                <Button variant="outlined" onClick={() => handleEditOpen()}>Добавить ролик</Button>
            </div>
                <ModalAddEdit
                    index={clips.length}
                    open={open}
                    setOpen={setOpen}
                    setClips={setClips}
                    clips={clips}
                    clip={activeClip}
                    setActiveClip ={setActiveClip}
                />
        </div>
    )
}

export default MpBrand