import './style.css'

const LabelBrand = ({brandName, color, customWidth = false}) => {
    const styleDiv = () => {
        let style = {}
        if (customWidth) {
            style.minWidth = customWidth
        }
        style.backgroundColor = color

        return style
    }

    return (
        <div className="label-brand" style={styleDiv()}>
            <p style={color && color !== "yellow" ? {color:'white'} : {}}>{brandName}</p>
        </div>
    )
}

export default LabelBrand