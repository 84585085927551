import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeMonth: "",
};

export const monthSlice = createSlice({
    name: "month",
    initialState,
    reducers: {
        selectMonth: (state, action) => {
            state.activeMonth = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export const { selectMonth } = monthSlice.actions;

export default monthSlice.reducer;
