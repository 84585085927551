import './style.css'
import {Button, Input} from "@mui/material";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {addNewBanner, getAllChanels, getFilesList, getMonthBanners} from "../../API/api";
import MonthChoose from "../../modules/month-choose";
import {useNavigate} from "react-router-dom";
import {RightInfoBanerTicker} from "../../modules/right-info-baner-ticker/RightInfoBanerTicker";
import {SelectDates} from "../../modules/select-dates/SelectDates";
import BannerInputSelect from "../../components/banner-input-select";
import {FileUpload} from "../../modules/File-Upload/FileUpload";

const AddBanner = () => {
    const {activeMonth} = useSelector((store) => store.month)
    const {activeChanel} = useSelector((store) => store.chanel)
    const { userName } = useSelector((store) => store.userName)
    const navigate = useNavigate()
    const [selectDates, setSelectDates] = useState([])
    const [priceRek, setPriceRek] = useState(0)
    const [bannerRU, setBannerRU] = useState('')
    const [bannerKG, setBannerKG] = useState('')
    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)
    // Calendar days table
    const daysWeekName = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const [lastPosition, setLastPosition] = useState(1)
    const getLastPosition = async () => {
        const banners = await getMonthBanners(localStorage.getItem("access_token"),
            `${month}`, `${chanels[activeChanel]?.id}`)
        const position = banners.at(-1).position
        await setLastPosition(Number(position) + 1)
    }

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            //Add data
            data.chanel = chanels[activeChanel].id
            data.user = userName
            data.month = monthName(activeMonth)
            data.banner_ru = bannerRU
            data.banner_kg = bannerKG
            data.days = selectDates.sort((a, b) => a - b)
            data.position = lastPosition
            // console.log(data);
            await addNewBanner(localStorage.getItem("access_token"),data)
            navigate('/banner')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    // Select Date, current month

    const [dayPerMonth, setDayPerMonth] = useState()
    const currentDays = () => {
        setDayPerMonth(new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate())
    }
    useEffect(() => {
        currentDays()
    }, [activeMonth])

    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }
    const getNumberWeekDay = (date) => (date.getDay())

     // Other Way Calendar
    const [customArrDayMonth, setCustomArrDayMonth] = useState([])

    const divCalendarTable = () => {
        setCustomArrDayMonth([])
        const month = new Date(activeMonth).getMonth()
        const year = new Date(activeMonth).getFullYear()
        const first = getWeekDay(new Date(year, month, 1))
        const indexFirstDay = daysWeekName.indexOf(first)
        const indexLastDay = getNumberWeekDay(new Date(year, month, dayPerMonth))

        for (let i=0; i < indexFirstDay; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, ""])
        }
        for (let i=1; i <= dayPerMonth; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, i])
        }
        if (indexLastDay !== 0) {
            for (let i = indexLastDay; i < 7; i++) {
                setCustomArrDayMonth(oldArray => [...oldArray, ""])
            }
        }
    }

    useEffect(() => {
        // calendarTable()
        divCalendarTable()
    }, [activeMonth, chanels, dayPerMonth])

    // Get Banner List from Server
    const [files, setFiles] = useState([])
    const getFiles = async () => {
        if (chanels) {
            const data = await getFilesList(
                localStorage.getItem("access_token"),
                `${chanels[activeChanel]?.id}`,
                `bannerUrl`
            );
            // Убираем лишние файлы
            const filter = data?.filter((el) =>
                el.split('.').at(-1) === "jpg" ||
                el.split('.').at(-1) === "png"
            )
            setFiles(filter)
        }
    }

    useEffect(() => {
        if (chanels.length > 0) {
            getFiles()
            getLastPosition()
        }
    },[chanels]);

    // // Sort List File
    // const [sortItems, setSortItems] = useState([])
    // const [itemFilter, setItemFilter] = useState('')
    // useEffect(() => {
    //     if (itemFilter.length > 0) {
    //         setSortItems(files?.filter((el) => el.toLowerCase().includes(itemFilter.toLowerCase())))
    //     } else {
    //         setSortItems(files)
    //     }
    //
    // }, [files, itemFilter])


    return (
        <div className="add-banner-page container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mp-head">
                    <div className="mp-head-left">
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Телеканал:</div>
                            <div className="mp-head_text_right">{chanels[activeChanel]?.chanel}</div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Пользователь:</div>
                            <div className="mp-head_text_right">{userName}</div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Брэнд:</div>
                            <div className="mp-head_text_right">
                                <Input
                                    className="mp-head_input"
                                    placeholder="Название"
                                    {...register("name",{
                                        required: true
                                    })}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Баннер РУ:</div>
                            <div className="mp-head_text_right">
                                {/*<input*/}
                                {/*    type="hidden"*/}
                                {/*    value={bannerRU}*/}
                                {/*    {...register("banner_ru",{*/}
                                {/*        required: true*/}
                                {/*    })}*/}
                                {/*/>*/}
                                <BannerInputSelect
                                    files={files}
                                    itemValue={bannerRU}
                                    setItemValue={setBannerRU}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Баннер КГ:</div>
                            <div className="mp-head_text_right">
                                {/*<input*/}
                                {/*    type="hidden"*/}
                                {/*    value={bannerKG}*/}
                                {/*    {...register("banner_kg",{*/}
                                {/*        required: true*/}
                                {/*    })}*/}
                                {/*/>*/}
                                <BannerInputSelect
                                    files={files}
                                    itemValue={bannerKG}
                                    setItemValue={setBannerKG}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <FileUpload
                                selectFolder={"bannerUrl"}
                                chanels={chanels}
                                getFiles={getFiles}
                            />
                            <Button
                                variant="outlined"
                                onClick={() => navigate("/list/bannerUrl")}
                            >Посмотреть все файлы</Button>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Стоимость:</div>
                            <Input
                                className="mp-head_input"
                                placeholder="Цена символа"
                                type="number"
                                value={priceRek}
                                onChange={(e) => {
                                    setPriceRek(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Месяц:</div>
                            <div className="mp-head_text_right">
                                <MonthChoose />
                            </div>
                        </div>
                    </div>
                    <div className={`mp-head-right`} style={{maxWidth:'350px'}}>
                        <RightInfoBanerTicker
                            selectDates={selectDates}
                            priceRek={priceRek}
                            monthName={monthName(activeMonth)}
                        />
                    </div>
                </div>
                <SelectDates
                    selectDates={selectDates}
                    setSelectDates={setSelectDates}
                    daysWeekName={daysWeekName}
                    customArrDayMonth={customArrDayMonth}
                />
            <div>
                <Button type={"submit"} variant="contained">Сохранить</Button>
                <Button variant="outlined" onClick={() => navigate(-1)}>Отмена</Button>
            </div>
            </form>
        </div>
    )
}

export default AddBanner