import './style.css'
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {getAllChanels, getTicker, setUpdateTicker} from "../../API/api";
import {Button, Input, TextareaAutosize} from "@mui/material";
import {RightInfoBanerTicker} from "../../modules/right-info-baner-ticker/RightInfoBanerTicker";
import MonthChoose from "../../modules/month-choose";
import {SelectDates} from "../../modules/select-dates/SelectDates";

const EditTicker = () => {
    const {id} = useParams();
    const {activeMonth} = useSelector((store) => store.month)
    const {activeChanel} = useSelector((store) => store.chanel)
    const { userName } = useSelector((store) => store.userName)
    const navigate = useNavigate()
    const [selectDates, setSelectDates] = useState([])

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    // Calendar days table
    const daysWeekName = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm();

    const [textRek, setTextRek] = useState('')
    const [priceRek, setPriceRek] = useState(0)

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            // data.chanel = selectChanels
            data.user = userName
            let text = textRek.split('\n')
            data.text = text.filter(item => item !== '' && item !== null && item !== undefined)
            data.text.map((el, index) => {
                return data.text[index] = el.replace(/\s+/g, ' ')
            })
            data.days = selectDates.sort((a, b) => a - b)
            // next data
            // console.log(data);
            await setUpdateTicker(localStorage.getItem("access_token"), id, data)
            navigate('/ticker')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    const [dayPerMonth, setDayPerMonth] = useState()
    const currentDays = () => {
        setDayPerMonth(new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate())
    }
    useEffect(() => {
        currentDays()
    }, [activeMonth])

    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }
    const getNumberWeekDay = (date) => (date.getDay())


    // Other Way Calendar
    const [customArrDayMonth, setCustomArrDayMonth] = useState([])

    const divCalendarTable = () => {
        setCustomArrDayMonth([])
        const month = new Date(activeMonth).getMonth()
        const year = new Date(activeMonth).getFullYear()
        const first = getWeekDay(new Date(year, month, 1))
        const indexFirstDay = daysWeekName.indexOf(first)
        const indexLastDay = getNumberWeekDay(new Date(year, month, dayPerMonth))

        for (let i=0; i < indexFirstDay; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, ""])
        }
        for (let i=1; i <= dayPerMonth; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, i])
        }
        if (indexLastDay !== 0) {
            for (let i = indexLastDay; i < 7; i++) {
                setCustomArrDayMonth(oldArray => [...oldArray, ""])
            }
        }
    }

    useEffect(() => {
        // calendarTable()
        divCalendarTable()
    }, [activeMonth, chanels, dayPerMonth])

    // Get Data
    const [dataTicker, setDataTicker] = useState(null)
    const getDataTicker = async () => {
        const data = await getTicker(localStorage.getItem("access_token"), id);
        setDataTicker(data)
    }
    useEffect(() => {
        getDataTicker();
    },[id]);

    useEffect(() => {
        if (dataTicker) {
            setSelectDates(dataTicker?.days)
            setTextRek(dataTicker?.text.join('\n'))
            // setStatus(dataMp?.status)
            // setReklama(dataMp?.reklama)
            reset(dataTicker)
        }
    },[dataTicker])


    return (
        <div className="add-banner-page container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mp-head">
                    <div className="mp-head-left">
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Телеканал:</div>
                            <div className="mp-head_text_right">{chanels?.[activeChanel]?.chanel}</div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Пользователь:</div>
                            <div className="mp-head_text_right">{userName}</div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Брэнд:</div>
                            <div className="mp-head_text_right">
                                <Input
                                    className="mp-head_input"
                                    placeholder="Название"
                                    {...register("name",{
                                        required: true
                                    })}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Текст:</div>
                            <div className="mp-head_text_right">
                                <TextareaAutosize
                                    className="ticker-textaria"
                                    placeholder="Текст бегущей строки"
                                    maxRows={10}
                                    {...register("text",{
                                        required: true
                                    })}
                                    value={textRek}
                                    onChange={(e) => {
                                        setTextRek(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Стоимость:</div>
                            <Input
                                className="mp-head_input"
                                placeholder="Цена символа"
                                type="number"
                                value={priceRek}
                                onChange={(e) => {
                                    setPriceRek(e.target.value);
                                }}
                            />
                        </div>

                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Месяц:</div>
                            <div className="mp-head_text_right">
                                <MonthChoose />
                            </div>
                        </div>
                    </div>
                    <div className={`mp-head-right`} style={{maxWidth:'350px'}}>
                        <RightInfoBanerTicker
                            textRek={textRek}
                            selectDates={selectDates}
                            priceRek={priceRek}
                            monthName={monthName(activeMonth)}
                        />
                    </div>
                </div>
                <SelectDates
                    selectDates={selectDates}
                    setSelectDates={setSelectDates}
                    daysWeekName={daysWeekName}
                    customArrDayMonth={customArrDayMonth}
                />
                <div>
                    <Button type={"submit"} variant="contained">Сохранить</Button>
                    <Button variant="outlined" onClick={() => navigate(-1)}>Отмена</Button>
                </div>
            </form>
        </div>
    )
}

export default EditTicker