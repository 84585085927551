import {useEffect, useState} from "react";
import {deleteSelectFile, getAllChanels, getFilesList, getSelectFile} from "../../API/api";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ConfirmModal from "../../components/confirm-modal";
import styled from "styled-components";
import {Button, Input, TablePagination} from "@mui/material";
import {FileListItem} from "../../modules/File-List-Item/FileListItem";
import {FileUpload} from "../../modules/File-Upload/FileUpload";

const Content = styled.div`
    display: flex;
    justify-content: space-between;
  padding: 15px;
`
const Title = styled.h3`
    text-align: center;
`
const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
  gap: 10px;
  width: 60%;
`

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  width: 40%;
  height: auto;
  border: #02154f 2px solid;
  border-radius: 5%;
  padding: 10px;
`

const BtnGroup = styled.div`
    display: flex;
  gap: 15px;
  justify-content: center;
  padding-bottom: 15px;
  border-bottom: #02154f 2px solid;

`

const FileList =() => {
    const {activeChanel} = useSelector((store) => store.chanel);
    const [files, setFiles] = useState([])
    const [chanels, setChanels] = useState([])
    const [sortItems, setSortItems] = useState([])
    const [itemFilter, setItemFilter] = useState('')
    const [selectFolder, setSelectFolder] = useState('')
    const [uploadFile, setUploadFile] = useState()
    const [updateFileList, setUpdateFileList] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    // Получаем телеканалы
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    useEffect(() => {
        setSelectFolder(params.folder)
    },[params.folder])

    // Получаем список имен баннеров
    const getFiles = async () => {
        if (selectFolder === "clipsUrl" || selectFolder === "bannerUrl") {
            const data = await getFilesList(
                localStorage.getItem("access_token"),
                `${chanels[activeChanel]?.id}`,
                `${selectFolder}`
            );
            // Убираем лишние файлы
            const filter = data.filter((el) =>
                el.split('.').at(-1) === "mp4" ||
                el.split('.').at(-1) === "mov" ||
                el.split('.').at(-1) === "jpg" ||
                el.split('.').at(-1) === "png"
            )
            setFiles(filter)
        }
    }

    useEffect(() => {
        if (chanels.length > 0) {
            getFiles();
        }
    },[chanels, selectFolder]);

    // Confirm Modal
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [deleteId, setDeleteId] = useState('')

    const ConfirmModalOpen = (id) => {
        setOpenConfirmModal(true);
        setDeleteId(id)
    };

    const deleteSelectItem = async (id) => {
        await deleteSelectFile(
            localStorage.getItem("access_token"),
            `${chanels[activeChanel]?.id}`,
            `${selectFolder}`,
            `${id}`
        )
        console.log(id)
        getFiles()
    }

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [startItem, setStartItem] = useState(0)
    const [endItem, setEndItem] = useState(10)

    useEffect(() => {
        setStartItem(page * rowsPerPage)
        setEndItem(page * rowsPerPage + rowsPerPage)
    }, [page, rowsPerPage])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (itemFilter.length > 0) {
            setSortItems(files?.filter((el) => el.toLowerCase().includes(itemFilter.toLowerCase())))
        } else {
            setSortItems(files)
        }

    }, [files, itemFilter])

    //Получаем картинку с сервера
    const [selectImage, setSelectImage] = useState()
    const [selectImageFormat, setSelectImageFormat] = useState('')
    const [selectImageLoading, setSelectImageLoading] = useState(false)


    const getImageFile = async (fileName) => {
        setSelectImageLoading(true)
        const data = await getSelectFile(
            localStorage.getItem("access_token"),
            `${chanels[activeChanel]?.id}`,
            `${selectFolder}`,
            `${fileName}`
        );
        setSelectImageFormat(fileName.split('.').at(-1))
        setSelectImage(data)
        console.log(data)
        setSelectImageLoading(false)
    }

    // TEST TIME
    const [videoLength, setVideoLength] = useState();
    const getLength = data => {
        console.log(data)
    };
    // console.log(videoLength)
    //END TEST TIME

    return (
        <div className="banner-page container">
            <Title>Список файлов телеканала {chanels[activeChanel]?.chanel}</Title>
            <BtnGroup>
                <Button
                    variant={selectFolder === "clipsUrl" ? "contained" : "outlined"}
                    onClick={() => navigate('/list/clipsUrl')}
                >
                    Ролики
                </Button>
                <Button
                    variant={selectFolder === "bannerUrl" ? "contained" : "outlined"}
                    onClick={() => navigate('/list/bannerUrl')}
                >
                    Баннеры
                </Button>
            </BtnGroup>
            <Content>
                <LeftColumn>
                    <div className="mp-head_text" style={{paddingBottom:20}}>
                        <div className="mp-head_text_left">Фильтр:</div>
                        <div className="mp-head_text_right">
                            <Input
                                className="mp-head_input"
                                placeholder="Содержит символ"
                                type="text"
                                value={itemFilter}
                                onChange={(e) => {
                                    setItemFilter(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    {sortItems?.slice(startItem, endItem).map((el) => (
                        <FileListItem key={el} el={el} getImageFile={getImageFile} ConfirmModalOpen={ConfirmModalOpen} />
                    ))}
                    <TablePagination
                        component="div"
                        count={sortItems?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{maxWidth:500}}
                    />
                    <div style={{display: "flex"}} >
                        {selectFolder === "bannerUrl" && <FileUpload selectFolder={selectFolder} chanels={chanels} getFiles={getFiles} />}
                        <Button variant="outlined" onClick={() => navigate(-1)}>Назад</Button>
                    </div>

                </LeftColumn>
                <RightColumn>
                    {selectImageFormat && !selectImageLoading ?
                        selectImageFormat === "mp4" ?
                            <video width="640" height="360" controls>
                                <source
                                    src={`data:video/mp4;base64,${selectImage.toString('base64')}`}
                                    type="video/mp4"
                                />
                            </video>
                            :
                            <img
                                // src={'https://inlocade.org/wp-content/uploads/2020/09/DFG_blau-e1612806315565-1024x683.png'}
                                src={selectImageFormat === 'png' ?
                                    `data:image/png;base64,${selectImage.toString('base64')}` :
                                    `data:image/jpg;base64,${selectImage.toString('base64')}`
                                }
                                alt={'Баннер'}
                                width={'100%'}
                                height={'auto'}
                            />
                        :
                        selectImageLoading ?
                            <>
                                <img
                                    src={'/image/smil_1.gif'}
                                    alt={'Выбери баннер'}
                                    width={'50%'}
                                    height={'auto'}
                                />
                                <img
                                    src={'/image/loadingLine_2.gif'}
                                    alt={'Выбери баннер'}
                                    width={'50%'}
                                    height={'auto'}
                                />
                            </>
                            :
                            <img
                                src={'/image/SelectImage.jpg'}
                                alt={'Выбери баннер'}
                                width={'100%'}
                                height={'auto'}
                            />
                    }
                </RightColumn>
            </Content>

            <ConfirmModal
                openConfirmModal={openConfirmModal}
                setOpenConfirmModal={setOpenConfirmModal}
                link={() => deleteSelectItem(deleteId)}
            />
        </div>
    )
}

export default FileList