import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  min-width: 150px;
  padding: 10px;
  > span {
    padding-top: 10px;
  }
`

const RightInfoBanerTicker = ({selectDates, priceRek, monthName, textRek = null }) => {
    const textLength = textRek ? textRek?.replace(/\s/g, '').length : 1
    return (
        <Container>
            <span><strong>Сумма:</strong></span>
            <span>
                            {textRek && <p>Символов: {textRek?.replace(/\s/g, '').length}</p>}
                                <p>Дней: {selectDates?.length}</p>
                            {textRek && <p>Итого: {textRek?.replace(/\s/g, '').length * selectDates?.length} симв</p>}
                            <p><strong>
                                Сумма: {textLength * selectDates?.length * priceRek} сом
                            </strong></p>
                        </span>
            <span><strong>Выбранные даты:</strong></span>
            <span>
                            {selectDates?.sort((a, b) => a - b).map((el) => (`${el} ${monthName}; `))}
                        </span>
        </Container>
    )
}

export {RightInfoBanerTicker}