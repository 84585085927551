import { request } from "./api-service";

//Users
export const loginUser = async () => {
    return await request("auth2/login/", "POST");
};
export const googleLoginFetch = async (token) => {
    return await request(`auth2/google/login`, "POST", JSON.stringify({token}), null, {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    });
};
export const refreshToken = async (data) => {
    return await request("auth2/refresh/", "POST", data);
};
export const logoutUser = async (data) => {
    return await request("auth2/logout/", "DELETE", data);
};
export const addUser = async (token, data) => {
    return await request(
        "users2/",
        "POST",
        data,
        null,
        {Authorization: `Bearer ${token}`}
        );
};
// export const editUser = async (data) => {
//     return await request("users2/", "PUT", data);
// };
export const allUsers = async (token) => {
    return await request("users2/all/", "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const userNameById = async (token, id) => {
    return await request(`users2/userName/${id}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdateUser = async (token, id, data) => {
    return await request(`users2/${id}`, "PUT", data,null,
        {Authorization: `Bearer ${token}`});
};

export const deleteUser = async (token, id) => {
    return await request(`users2/${id}`, "DELETE", null, null,
        {Authorization: `Bearer ${token}`});
};
//Roles Group
export const allRoles = async (token) => {
    return await request("roles/all", "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const addRole = async (token, data) => {
    return await request("roles/","POST", data,null,
        {Authorization: `Bearer ${token}`});
};
export const roleById = async (token, id) => {
    return await request(`roles/${id}`,"GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdateRole = async (token, id, data) => {
    return await request(`roles/${id}`, "PUT", data,null,
        {Authorization: `Bearer ${token}`});
};

export const deleteRole = async (token, id) => {
    return await request(`roles/${id}`, "DELETE", null, null,
        {Authorization: `Bearer ${token}`});
};

//Profile
export const userProfile = async (token) => {
    return await request("users2/me/", "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

//Schedule
export const addNewSchedule = async (token, data) => {
    return await request(`schedule/`, "POST", data,null,
        {Authorization: `Bearer ${token}`});
};
export const getAllSchedule = async (token) => {
    return await request("schedule/", "GET",
    null,null,
        {Authorization: `Bearer ${token}`});
};
export const getSchedule = async (token, id) => {
    return await request(`schedule/${id}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const getDaySchedule = async (token, monthName, chanel, day) => {
    return await request(`schedule/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const getNumberLastDay = async (token, monthName, chanel) => {
    return await request(`schedule/${monthName}/${chanel}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdateSchedule = async (token, id, data) => {
    return await request(`schedule/${id}`, "PUT", data, null,
        {Authorization: `Bearer ${token}`});
};

// MP
export const getAllMp = async (token) => {
  return await request("mptime/", "GET",null,null,
      {Authorization: `Bearer ${token}`});
};
export const getActiveMP = async (token) => {
    return await request("mptime/active", "GET",null,null,
        {Authorization: `Bearer ${token}`});
  };
export const getMP = async (token, id) => {
    return await request(`mptime/${id}`, "GET",null,null,
        {Authorization: `Bearer ${token}`});
};
export const addNewMP = async (token, data) => {
    return await request(`mptime/`, "POST", data,null,
        {Authorization: `Bearer ${token}`});
};

export const getMonthMP = async (token, monthName, chanel) => {
    return await request(`mptime/${monthName}/${chanel}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const getGuestMonthMP = async (monthName, chanel) => {
    return await request(`mptime/guest/${monthName}/${chanel}`, "GET");
};

export const getDayRek = async (token, monthName, chanel, day) => {
    return await request(`mptime/rek/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const getDayRekBlock = async (token, monthName, chanel, day, block) => {
    return await request(`mptime/rek/${monthName}/${chanel}/${day}/${block}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};
export const getDayRekBlockWithoutID = async (token, monthName, chanel, day, block, id) => {
    return await request(`mptime/rek/${monthName}/${chanel}/${day}/${block}/${id}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const setActiveMP = async (token, id, data) => {
    return await request(`mptime/${id}`, "PATCH", data,null,
        {Authorization: `Bearer ${token}`});
};

export const setUpdateMP = async (token, id, data) => {
    return await request(`mptime/${id}`, "PUT", data,null,
        {Authorization: `Bearer ${token}`});
};

export const deleteMpTable = async (token, id) => {
    return await request(`mptime/${id}`, "DELETE",null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdatePosition = async (token, id, data) => {
    return await request(`mptime/position/${id}`, "PATCH", data,null,
        {Authorization: `Bearer ${token}`});
};

// Clips
export const addNewClips = async (token, data) => {
    return await request(`clips/`, "POST", data,null,
        {Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'},
        // {"Content-Type": "multipart/form-data"}
        );
};

// Files
export const getFilesList = async (token, id, folder) => {
    return await request(`files/${id}/${folder}`, "GET", null,null,
        {Authorization: `Bearer ${token}`});
};

export const getSelectFile = async (token, id, folder, fileName) => {
    return await request(`files/${id}/${folder}/${fileName}`, "GET", null,null,
        {Authorization: `Bearer ${token}`});
};
export const deleteSelectFile = async (token, id, folder, fileName) => {
    return await request(`files/${id}/${folder}/${fileName}`, "POST", null,null,
        {Authorization: `Bearer ${token}`});
};
// Files
export const uploadFile = async (token, id, folder, data) => {
    return await request(`files/${id}/${folder}`, "POST", data,null,
        {Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'});
};


// Banners
export const addNewBanner = async (token, data) => {
    return await request(`banners/`, "POST", data,null,
        {Authorization: `Bearer ${token}`});
};
export const getAllBanners = async (token) => {
    return await request("banners/", "GET",null,null,
        {Authorization: `Bearer ${token}`});
};
export const getBanner = async (token, id) => {
    return await request(`banners/${id}`, "GET",null,null,
        {Authorization: `Bearer ${token}`});
};
export const getMonthBanners = async (token, monthName, chanel) => {
    return await request(`banners/${monthName}/${chanel}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const getDayBanners = async (token, monthName, chanel, day) => {
    return await request(`banners/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const getDayBannersText = async (token, monthName, chanel, day) => {
    return await request(`banners/text/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const setUpdateBanner = async (token, id, data) => {
    return await request(`banners/${id}`, "PUT", data,null,
        {Authorization: `Bearer ${token}`});
};
export const deleteBanner = async (token, id) => {
    return await request(`banners/${id}`, "DELETE",null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdateBannerFile = async (token, id) => {
    return await request(`banners/record/${id}`, "GET", null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdatePositionBanners = async (token, id, data) => {
    return await request(`banners/position/${id}`, "PATCH", data,null,
        {Authorization: `Bearer ${token}`});
};

// Бегушка...
export const addNewTicker = async (token, data) => {
    return await request(`ticker/`, "POST", data,null,
        {Authorization: `Bearer ${token}`});
};
export const getAllTicker = async (token) => {
    return await request("ticker/", "GET",null,null,
        {Authorization: `Bearer ${token}`});
};
export const getTicker = async (token, id) => {
    return await request(`ticker/${id}`, "GET",null,null,
        {Authorization: `Bearer ${token}`});
};
export const getMonthTicker = async (token, monthName, chanel) => {
    return await request(`ticker/${monthName}/${chanel}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const getDayTicker = async (token, monthName, chanel, day) => {
    return await request(`ticker/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const getDayTickerText = async (token, monthName, chanel, day) => {
    return await request(`ticker/text/${monthName}/${chanel}/${day}`, "GET",
        null,null,
        {Authorization: `Bearer ${token}`});
};

export const setUpdateTicker = async (token, id, data) => {
    return await request(`ticker/${id}`, "PUT", data,null,
        {Authorization: `Bearer ${token}`});
};
export const deleteTicker = async (token, id) => {
    return await request(`ticker/${id}`, "DELETE",null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdateTickerFile = async (token, id) => {
    return await request(`ticker/record/${id}`, "GET", null,null,
        {Authorization: `Bearer ${token}`});
};
export const setUpdatePositionTicker = async (token, id, data) => {
    return await request(`ticker/position/${id}`, "PATCH", data,null,
        {Authorization: `Bearer ${token}`});
};

//Chanels
export const getAllChanels = async () => {
  return await request(`chanels/`,"GET");
};
export const getChanel = async (id) => {
    return await request(`chanels/${id}`,"GET");
};
export const addNewChanel = async (token, data) => {
    return await request(`chanels/`, "POST", data,null,
        {Authorization: `Bearer ${token}`});
};
export const updateChanel = async (token, id, data) => {
    return await request(`chanels/${id}`, "PATCH", data,null,
        {Authorization: `Bearer ${token}`});
};


// OLD PROJECT FOR EXAMPLE  
export const deleteProduct = async (id) => {
  return await request(`products/${id}`, "DELETE");
};
export const addProduct = async (data) => {
  return await request(`products/`, "POST", data);
};
export const changeProduct = async (id, data) => {
  return await request(`products/${id}`, "PUT", data);
};