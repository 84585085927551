import './style.css'
import ExcelExampleExport from "../../modules/excel-parse-modules/ExcelExampleExport";
import ExcelRequestsImport from "../../modules/excel-parse-modules/ExcelRequestsImport";
import {useEffect, useState} from "react";

const ExcelParse = () => {
    // EXEL WORK TABLE
    const [data, setData] = useState([]);
    const [schedule, setSchedule] = useState([]);

    const createRequests = () => {
        console.log(data);
    };

    const worksheets = [
        {
            name: "Requests",
            columns: [
                { label: "Full Name", value: "name" },
                { label: "Email", value: "email" },
                { label: "Template", value: "template" }
            ],
            data: [
                {
                    name: "Bob Ross",
                    email: "boss_ross@gmail.com",
                    template: "Accounts Receivables"
                }
            ]
        }
    ];

    //parse start
    // console.log(data[2]?.[0])
    // console.log(data)
    const filterData = () => {
        data.filter((el, index) => {
            if (el[0] === "Logo 50") {
                return el
            }
            if (el[0] !== "Logo 50"){
                delete data[index]
            }
        })
    }
    useEffect(() =>{
        if (data) {
            // filterData()
            bolvanka()
        }
    },[data])
    // console.log(data[31])
    const bolvanka = () => {
        let result = []
        data.map((el, index)=> {
            const enf = el[7] ? el[7].split('\n', 1) : ""
            const anonce = el[2].split(' ',1)
            // console.log(el[7] ? el[7].split('\n', 1) : "")
            //Первый пункт блока
            if(el[0] === "Logo 50" && data[index - 1]?.[0] !== "Logo 50") {
                result.push(`Начало ${el[1]}`)
                if(el[9] === "СЗ") {
                    result.push(`НЕГР старт`)
                }
            }
            // Бампер стар серый
            if(el[0] === "Logo 50" && el[7] === "_STF") {
                result.push(`Бампер старт серый`)
            }
            // Бампер конец серый
            // if(el[0] === "Logo 50" && el[7] === "_ENF\n-00:00:30:00/00:00:05:00") {
            if(el[0] === "Logo 50" && enf[0] === "_ENF") {
                result.push(`Бампер стоп серый`)
            }
            // Бампер стар зеленый
            if(el[0] === "Logo 50" && el[7] === "_STR") {
                result.push(`Бампер старт Зеленый`)
            }
            // Бампер конец зеленый
            if(el[0] === "Logo 50" && el[7] === "_ENR") {
                result.push(`Бампер стоп Зеленый`)
            }
            // Только негр в блоке
            if(el[0] === "Logo 50"
                && data[index - 1]?.[0] === "Logo 50"
                && el[9] === "СЗ"
            ) {
                result.push(`Негро блок`)
            }
            // Анонс
            if(el[0] === "Logo 50" && anonce[0] === "Анонс"
            ) {
                result.push(`Анонс`)
            }
            // Последний пункт блока
            if(el[0] === "Logo 50" && data[index + 1]?.[0] !== "Logo 50") {
                if(el[9] === "СЗ") {
                    result.push(`НЕГР конец`)
                }
                result.push(`Конец`)
            }
        })
        setSchedule(result)
    }
    console.log(schedule)

    return (
        <div className="excel-parse container">
            <p>TEST EXEL PARSE</p>
            <div>
                <ExcelExampleExport filename="requests.xlsx" worksheets={worksheets} />
                <ExcelRequestsImport uploadHandler={setData} />
                <button onClick={createRequests}>Create</button>
            </div>
            <hr/>
            <hr/>
            <hr/>
            <div>
                {schedule.map(el => <p>{el}</p>)}
            </div>
        </div>
    )
}

export default ExcelParse