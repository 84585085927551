import './style.css'
import {useSelector} from "react-redux";
import parse from "paste-from-excel";
import MptableInput from "../../components/mptable-input";

const MpTable = (
    {
        chanels,
        dayPerMonth,
        register,
        setValue,
        setRekBlockList,
        clips,
        id,
        setErrTimeBlock,
        errTimeBlock,
        watch,
        setDayName,
        setRekIndex
    }) => {

    const {activeChanel} = useSelector((store) => store.chanel);
    const {activeMonth} = useSelector((store) => store.month);

    // const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
    //     month: 'long', year: 'numeric'
    // }))
    // const month = monthName(activeMonth)
    // const getRekBlockList = async (day, block) => {
    //     const data = await getDayRekBlock(`${month}`, `${chanels[activeChanel]?.id}`, `${day}`,`${block}`)
    //     setRekBlockList(data)
    // }

    // TEST COPY INPUT VALUE
    const handlePaste = (index, elm, e) => {
        return parse(e);
    };

    const handlePaste1 = (e, elm, res) => {
        setValue(`reklama.${elm}.${res}`, `${e.target.value.replace(/\s/g, "")}`, { shouldTouch: true });
        };


    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }

    // console.log(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), 12)

    return (
        <div className="mp-table">
            {/*<div className="table-time-value">*/}
            {/*    {chanels?.[activeChanel]?.rekBlock?.map((el, index) => (*/}
            {/*        <span key={index}>{el}</span>*/}
            {/*    ))}*/}
            {/*</div>*/}
            <div className="table-time-value">
                <table>
                    <tr className="text-center">
                        <th
                            style={{
                                width: "22px",
                                border: "1px solid black",
                                minHeight: "22px",
                                height: "100%",
                            }}
                        > &nbsp;
                        </th>
                    </tr>
                    <tr className="text-center">
                        <th
                            style={{
                                width: "22px",
                                border: "1px solid black",
                                minHeight: "22px",
                                height: "100%",
                            }}
                        > &nbsp;
                        </th>
                    </tr>

                            {chanels?.[activeChanel]?.rekBlock?.map((el, index) => (
                                <tr key={index}>
                                    <td
                                        style={{
                                            width: "50px",
                                            maxHeight: "24px",
                                            border: "1px solid black",
                                            borderRadius: "0px",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        <p style={{
                                            width: "50px",
                                            minHeight: "24px",
                                            border: "1px solid black",
                                            borderRadius: "0px",
                                            wordWrap: "break-word",
                                            padding: "1px 2px",
                                            margin: "0",
                                        }}>
                                        {el}
                                        </p>
                                    </td>
                                </tr>
                                ))}
                </table>
            </div>


            <div className="scroll-table">
                <table>
                    <tr className="text-center">
                        {dayPerMonth?.map((elm, ind) => {
                            return (

                                <th
                                    key={ind}
                                    style={{
                                        width: "22px",
                                        border: "1px solid black",
                                    }}
                                >
                                        {elm}
                                </th>

                            );
                        })}
                    </tr>
                    <tr className="text-center">
                        {dayPerMonth?.map((elm, ind) => {
                            return (

                                <th
                                    key={ind}
                                    style={{
                                        width: "22px",
                                        border: "1px solid black",
                                        fontWeight: "400",
                                    }}
                                >
                                    {getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), elm))}
                                </th>

                            );
                        })}
                    </tr>
                    {chanels?.[activeChanel]?.rekBlock?.map((res, index) => {
                        return (
                            <tr key={index}>
                                {dayPerMonth.map((elm, i) => {
                                    return (
                                        <td
                                            key={i}
                                            style={{
                                                width: "22px",
                                                minHeight: "24px",
                                                border: "1px solid black",
                                                borderRadius: "0px",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            <MptableInput
                                                setRekBlockList={setRekBlockList}
                                                chanels={chanels}
                                                handlePaste1={handlePaste1}
                                                handlePaste={handlePaste}
                                                elm={elm}
                                                res={res}
                                                index={index}
                                                i={i}
                                                register={register}
                                                clips={clips}
                                                dayName={getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), elm))}
                                                id={id}
                                                setErrTimeBlock={setErrTimeBlock}
                                                errTimeBlock={errTimeBlock}
                                                watch={watch}
                                                setDayName={setDayName}
                                                setRekIndex={setRekIndex}
                                            />
                                        </td>

                                    );
                                })}
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    )
}

export default MpTable