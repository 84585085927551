import './style.css'
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAllChanels} from "../../API/api";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from "@mui/icons-material/Check";
import {Alert} from "@mui/material";
import {useForm} from "react-hook-form";

const grid = 8;

const dropDownCSS = () => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: "red"
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    //display: "flex",
    background: isDraggingOver ? "lightblue" : "grey",
    padding: grid,
    width: 600
});

const ScheduleDnd = ({data, rek, lastTime, chanels, scheduleSave, index, saved, dataSaved}) => {
    const getItems = () => {
        if (data) {
            data.map((mp, index) => {
                const data1 = mp[rek]
                if (data1) {
                    setItems(oldArray => [...oldArray, {
                        id: `${rek}-${index}`,
                        content: `${data1.name}`
                    }])
                }})}}


    useEffect(() => {
        setItems([])
        if (data.length > 0) {
            getItems()
        }
    },[data, rek])

        //     count =>
        // Array.from({ length: count }, (v, k) => k).map(k => ({
        //     id: `item-${k}`,
        //     content: `item ${k}`
        // }));

    const [items, setItems] = useState([]);
    useEffect(() => {
        const name = items.length > 0 ? items[0].id.split('-')[0] : ""
        const content = []
        items?.map((el) => {
            content.push(el.content)
        })
        // console.log(name, content)
        // const obj = {[name]: content}
        // console.log(obj)
        // scheduleSave[name] = content
        scheduleSave[index] = {[name]: content}
    }, [items])

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    //This will run, when the element is dragged from its position
    const onDragEnd = ({ source, destination }) => {
        // If dropped outside the list
        if (!destination) return;

        // If dropped inside the list
        const newItems = reorder(items, source.index, destination.index);
        setItems(newItems);
    };

    const removeItem = deleteItem => {
        let newItems = items.filter(item => item.id !== deleteItem.id);
        setItems(newItems);
    };

    const {activeChanel} = useSelector((store) => store.chanel);

    // Alert Copy
    const [showTopBtn, setShowTopBtn] = useState(false);
    const showAlert = () => {
        setShowTopBtn(true)
        setTimeout(() => setShowTopBtn(false), 3000)
    }

    const secToTime = (sec) => {
        let s = (sec % 60).toString();
        let m = Math.floor(sec / 60 % 60).toString();
        let h = Math.floor(sec / 60 / 60 % 60).toString();
        return `${h.padStart(2,'0')}:${m.padStart(2,'0')}:${s.padStart(2,'0')}.00`;
    }

    const copyBlock = (containerid) => {
        let textarea = document.createElement('textarea');
        textarea.id = 'temp';
        textarea.style.height = 0;
        document.body.appendChild(textarea);
        textarea.value = document.getElementById(containerid).innerText;
        let selector = document.querySelector('#temp');
        selector.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        showAlert()
    }

    const endBanners = () => {
        if (chanels[activeChanel]?.id === 2 && lastTime > 4) {
            return (
                <>
                    <p>{`<comment title="Банера" />`}</p>
                    <p>{`<movie file="${chanels[activeChanel]?.bannerClipUrl}" duration="${secToTime(lastTime)}" logo="0" titles="0" actual_duration_from_file="0" no_blocking="1" />`}</p>
                    <p>{`<titleoff target="TSF_RollCrawl" crossfade="0.04" duration="00:00:00.04" />`}</p>
                    <p>{`<titleon target="TSF_CaptionPro" />`}</p>
                    <p>{`<pauseallfinish logo="0" titles="1" />`}</p>
                    <p>{`<titleoff target="TSF_CaptionPro" crossfade="0.04" duration="00:00:00.04" />`}</p>
                    <p>{`<titleon target="TSF_RollCrawl" />`}</p>
                </>
            )
        }
        if (chanels[activeChanel]?.id === 1 && lastTime > 14) {
            return (
                <>
                    <p>{`<movie file="${chanels[activeChanel]?.bannerClipUrl}" duration="${secToTime(lastTime)}" logo="0" titles="0" actual_duration_from_file="0" no_blocking="1" />`}</p>
                    <p>{`<titleon target="TSF_CaptionPro" />`}</p>
                    <p>{`<pauseallfinish logo="0" titles="1" />`}</p>
                    <p>{`<titleoff target="TSF_CaptionPro" crossfade="0.04" duration="00:00:00.04" />`}</p>
                </>
            )
        }
    }

    return (
        <div className="dnd_main">
                <div className="dnd_left">
                    {saved ?
                        <div style={{background: "grey", padding: 8, width: 600}}>
                            {dataSaved?.map((el) => {
                                const arr = el[rek]
                                return (
                                    arr?.map((clip, index) => (
                                        <div key={index} className="schedule-save-item">
                                            <span>{clip}</span>
                                        </div>
                                    ))
                                )
                            })}
                        </div>
                        :
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" >
                                {(droppableProvided, droppableSnapshot) => (
                                    <div
                                        ref={droppableProvided.innerRef}
                                        style={getListStyle(droppableSnapshot.isDraggingOver)}
                                    >
                                        {items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >
                                                        <span>{item.content}</span>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {droppableProvided.placeholder}
                                        {/*<div style={dropDownCSS()}>+</div>*/}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        }
                </div>
                <div className="dnd_right" id={`block${rek}`}>
                    {saved ?
                        dataSaved?.map((el) => {
                            const arr = el[rek]
                            return (
                                arr?.map((clip, index) => (
                                    <p key={index}>
                                        {`<movie file=\"${chanels[activeChanel]?.clipsUrl}${clip}\" logo=\"0\" titles=\"0\" />`}
                                    </p>
                                ))
                            )
                        })
                        :
                        items?.map((brand, index) => (
                            <p key={brand.id}>
                                {`<movie file=\"${chanels[activeChanel]?.clipsUrl}${brand.content}\" logo=\"0\" titles=\"0\" />`}
                            </p>
                        ))
                    }
                    {endBanners()}
                </div>
            <div style={{color: "blue"}} className="dnd-btn-copy">
                <ContentCopyIcon style={{cursor: "pointer"}} onClick={() => copyBlock(`block${rek}`)} />
                <div className="dnd_alert_copy">
                    {showTopBtn && (<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                        Скопированно {rek}
                    </Alert>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ScheduleDnd