import styled from "styled-components";
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {uploadFile} from "../../API/api";
import {useSelector} from "react-redux";


const Container = styled.div`
`
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUpload = ({selectFolder, chanels, getFiles}) => {
    const {activeChanel} = useSelector((store) => store.chanel);
    const [selectedFile, setSelectedFile] = useState();
    const [resizeBanner, setResizeBanner] = useState();
    const [nameBanner, setNameBanner] = useState();

    // New File Upload
    const newFileUpload = async (event) => {
        if (selectFolder === 'bannerUrl') {
            const img = event.target.files[0]
            setNameBanner(event.target.files[0].name)
            const reader = new FileReader()
            reader.onload = (e) => {
                const originalImg = new Image()
                originalImg.src = e.target.result

                compressImage(originalImg.src)
            }
            reader.readAsDataURL(img)
            const compressImage = (base64) => {
                const canvas = document.createElement('canvas')
                const img = document.createElement('img')

                img.onload = () => {
                    let width = img.width
                    let height = img.height
                    const maxHeight = chanels[activeChanel]?.chanel === "CTC" ? 520 : 652
                    const maxWidth = chanels[activeChanel]?.chanel === "CTC" ? 928 : 1152
                    // CTC 928 / 520

                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width))
                        width = maxWidth
                    }
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height))
                        height = maxHeight
                    }
                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob((blob) => {
                        setSelectedFile(blob);
                    });

                    const compressData = canvas.toDataURL('image/png', 0.7)
                    // console.log(ctx)
                    setResizeBanner(compressData)

                }
                img.onerror = (err) => {
                    return err
                }
                img.src = base64
            }
        }
    }

    useEffect(() => {
        if (selectedFile) {
            handleSubmission()
            setSelectedFile()
        }
    },[selectedFile])

    const handleSubmission = async () => {
        try {
        let data = new FormData();
        await data.append("clip_name", `${nameBanner?.substring(0, nameBanner?.lastIndexOf('.'))}.png`)
        await data.append("file", selectedFile)
        await uploadFile(
            localStorage.getItem("access_token"),
            `${chanels[activeChanel]?.id}`,
            `bannerUrl`,
            data);
        alert('file upload')
            getFiles()
            document.getElementById('input_file').value=''
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    return (
        <Container>
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                Загрузить файл
                <VisuallyHiddenInput
                    type="file"
                    accept={selectFolder === "clipsUrl" ? "video/mp4, .mov" : "image/jpeg,image/png"}
                    onChange={newFileUpload}
                    id={'input_file'}
                />
            </Button>
        </Container>
    )
}

export {FileUpload}