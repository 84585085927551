import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //activeChanel: "Choose",
    userRole: "GUEST",
    isAdmin: false,
    isDirector: false,
    isOperator: false,
    isManager: false,
    isGuest: true
};

export const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        changeRole: (state, action) => {
            state.userRole = action.payload;
            // localStorage.setItem("activeChanel", action.payload);
        },
        changeAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        changeDirector: (state, action) => {
            state.isDirector = action.payload;
        },
        changeOperator: (state, action) => {
            state.isOperator = action.payload;
        },
        changeManager: (state, action) => {
            state.isManager = action.payload;
        },
        changeGuest: (state, action) => {
            state.isGuest = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export const {
    changeRole,
    changeAdmin,
    changeDirector,
    changeOperator,
    changeManager,
    changeGuest,
} = rolesSlice.actions;

export default rolesSlice.reducer;
