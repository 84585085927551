import './style.css'
import {Box, Button, FormControl, Input, InputLabel, MenuItem, Select, TextareaAutosize} from "@mui/material";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {addNewTicker, getAllChanels, getMonthTicker} from "../../API/api";
import MonthChoose from "../../modules/month-choose";
import {useNavigate} from "react-router-dom";
import {RightInfoBanerTicker} from "../../modules/right-info-baner-ticker/RightInfoBanerTicker";
import {SelectDates} from "../../modules/select-dates/SelectDates";

const AddTicker = () => {
    const {activeMonth} = useSelector((store) => store.month)
    const {activeChanel} = useSelector((store) => store.chanel)
    const { userName } = useSelector((store) => store.userName)
    const navigate = useNavigate()
    const [selectDates, setSelectDates] = useState([])
    const [selectChanels, setSelectChanels] = useState('')

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)
    // Calendar days table
    const daysWeekName = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const [textRek, setTextRek] = useState('')
    const [priceRek, setPriceRek] = useState(0)

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    useEffect(() => {
        setSelectChanels(chanels?.[activeChanel]?.chanel)
    }, [chanels])

    const [lastPosition, setLastPosition] = useState(1)
    const getLastPosition = async () => {
        const ticker = await getMonthTicker(localStorage.getItem("access_token"),
            `${month}`, `${chanels[activeChanel]?.id}`)
        const position = ticker.at(-1).position
        await setLastPosition(Number(position) + 1)
    }
    useEffect(() => {
        if (chanels.length > 0) {
            getLastPosition()
        }
    },[chanels]);

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            //Add mocked data
            // data.chanel = chanels[activeChanel].id
            // data.chanel = selectChanels
            data.user = userName
            data.month = monthName(activeMonth)
            let text = textRek.split('\n')
            data.text = text.filter(item => item !== '' && item !== null && item !== undefined)
            data.text.map((el, index) => {
                return data.text[index] = el.replace(/\s+/g, ' ')
            })
            data.days = selectDates.sort((a, b) => a - b)
            data.position = lastPosition
            if (selectChanels === 'all' || selectChanels === 'Любимый+СТС') {
                data.chanel = chanels.filter(obj => obj.chanel === 'Любимый')[0].id
                await addNewTicker(localStorage.getItem("access_token"),data)
                // console.log(data)
                data.chanel = chanels.filter(obj => obj.chanel === 'CTC')[0].id
                await addNewTicker(localStorage.getItem("access_token"),data)
                // console.log(data)
                if (selectChanels === 'all') {
                    data.chanel = chanels.filter(obj => obj.chanel === 'CineMax')[0].id
                    await addNewTicker(localStorage.getItem("access_token"),data)
                    // console.log(data)
                }
            } else {
                // data.chanel = selectChanels
                data.chanel = chanels.filter(obj => obj.chanel === selectChanels)[0].id
                await addNewTicker(localStorage.getItem("access_token"),data)
                // console.log(data)
            }
            // console.log(data)
            // next data
            // await addNewTicker(localStorage.getItem("access_token"),data)
            navigate('/ticker')
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    const [dayPerMonth, setDayPerMonth] = useState()
    const currentDays = () => {
        setDayPerMonth(new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate())
    }
    useEffect(() => {
        setSelectDates([])
        currentDays()
    }, [activeMonth])

    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }
    const getNumberWeekDay = (date) => (date.getDay())

    // Other Way Calendar
    const [customArrDayMonth, setCustomArrDayMonth] = useState([])

    const divCalendarTable = () => {
        setCustomArrDayMonth([])
        const month = new Date(activeMonth).getMonth()
        const year = new Date(activeMonth).getFullYear()
        const first = getWeekDay(new Date(year, month, 1))
        const indexFirstDay = daysWeekName.indexOf(first)
        const indexLastDay = getNumberWeekDay(new Date(year, month, dayPerMonth))

        for (let i=0; i < indexFirstDay; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, ""])
        }
        for (let i=1; i <= dayPerMonth; i++) {
            setCustomArrDayMonth(oldArray => [...oldArray, i])
        }
        if (indexLastDay !== 0) {
            for (let i = indexLastDay; i < 7; i++) {
                setCustomArrDayMonth(oldArray => [...oldArray, ""])
            }
        }
    }

    useEffect(() => {
        // calendarTable()
        divCalendarTable()
    }, [activeMonth, chanels, dayPerMonth])

    // console.log(textRek.split('\n'))
    // console.log(textRek.replace(/\s/g, '').length)
    // console.log(textRek.replace(/\s+/g, ' '))
    // console.log(selectChanels)

    return (
        <div className="add-banner-page container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mp-head">
                    <div className="mp-head-left">
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Телеканал:</div>
                            {/*<div className="mp-head_text_right">{chanels[activeChanel]?.chanel}</div>*/}
                            <div className="mp-head_text_right">
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Канал</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectChanels ? selectChanels : ""}
                                            label="Канал"
                                            onChange={(e) => {
                                                setSelectChanels(e.target.value);
                                            }}
                                        >
                                            {chanels?.map(el => (
                                                <MenuItem key={el.id} value={el.chanel}>{el.chanel}</MenuItem>
                                            ))}
                                            <MenuItem value={"Любимый+СТС"}>Любимый + СТС</MenuItem>
                                            <MenuItem value={"all"}>Все</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Пользователь:</div>
                            <div className="mp-head_text_right">{userName}</div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Брэнд:</div>
                            <div className="mp-head_text_right">
                                <Input
                                    className="mp-head_input"
                                    placeholder="Название"
                                    {...register("name",{
                                        required: true
                                    })}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Текст:</div>
                            <div className="mp-head_text_right">
                                <TextareaAutosize
                                    className="ticker-textaria"
                                    placeholder="Текст бегущей строки"
                                    maxRows={10}
                                    {...register("text",{
                                        required: true
                                    })}
                                    value={textRek}
                                    onChange={(e) => {
                                        setTextRek(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Стоимость:</div>
                            <Input
                                className="mp-head_input"
                                placeholder="Цена символа"
                                type="number"
                                value={priceRek}
                                onChange={(e) => {
                                    setPriceRek(e.target.value);
                                }}
                            />
                        </div>

                        <div className="mp-head_text">
                            <div className="mp-head_text_left">Месяц:</div>
                            <div className="mp-head_text_right">
                                <MonthChoose />
                            </div>
                        </div>
                    </div>
                    <div className={`mp-head-right`} style={{maxWidth:'350px'}}>
                        <RightInfoBanerTicker
                            textRek={textRek}
                            selectDates={selectDates}
                            priceRek={priceRek}
                            monthName={monthName(activeMonth)}
                        />
                    </div>
                </div>
                <SelectDates
                    selectDates={selectDates}
                    setSelectDates={setSelectDates}
                    daysWeekName={daysWeekName}
                    customArrDayMonth={customArrDayMonth}
                />

                <div>
                    <Button type={"submit"} variant="contained">Сохранить</Button>
                    <Button variant="outlined" onClick={() => navigate(-1)}>Отмена</Button>
                </div>
            </form>
        </div>
    )
}

export default AddTicker