import { configureStore } from "@reduxjs/toolkit";
import chanelReduser from "./chanels";
import monthReduser from "./select-month";
import rolesReduser from "./role";
import profileReduser from "./profile";

export const store = configureStore({
    reducer: {
        chanel: chanelReduser,
        month: monthReduser,
        userRole: rolesReduser,
        isAdmin: rolesReduser,
        isDirector: rolesReduser,
        isOperator: rolesReduser,
        isManager: rolesReduser,
        isGuest: rolesReduser,
        userName: profileReduser,
        email: profileReduser,
        id: profileReduser,
    },
});