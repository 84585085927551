import "./style.css"
import MonthSlider from "../../modules/month-slider";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    addNewSchedule,
    getAllChanels,
    getDayRek,
    getDaySchedule,
    getNumberLastDay,
    setUpdateSchedule
} from "../../API/api";
import ScheduleDnd from "../../modules/schedule-dnd";
import {Button, Fab} from "@mui/material";
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useForm} from "react-hook-form";


const Schedule = () => {
    const {activeMonth} = useSelector((store) => store.month);
    const {activeChanel} = useSelector((store) => store.chanel);
    const [dayPerMonth, setDayPerMonth] = useState([])
    const [currentDay, setCurrentDay] = useState(new Date().getDate())
    const [saved, setSaved] = useState(false)
    const { userName } = useSelector((store) => store.userName)

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)
    //useForm
    const {
        handleSubmit,
        formState: {errors},
    } = useForm();

    const currentDays = () => {
        setDayPerMonth([])
        const lastDayMonth = new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate()
        for (let i = 1; i <= lastDayMonth; i++) {
            setDayPerMonth(oldArray => [...oldArray, i])
        }
    }
    useEffect(() => {
        currentDays()
        if (new Date(activeMonth).getMonth() !== new Date().getMonth()) {
            setCurrentDay(1)
        }
    }, [activeMonth])

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const [arrBlocks, setArrBlocks] = useState([])
    const getBlocks = async (block) => {
        const data = await getDayRek(localStorage.getItem("access_token"),
            `${month}`, `${chanels[activeChanel]?.id}`, `${block}`)
        setArrBlocks(data)
    }
    //Save Schedule to DataBase
    const [scheduleSave, setScheduleSave] = useState([])
    const onSave = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            data.chanel = chanels[activeChanel]?.id
            data.month = monthName(activeMonth)
            data.day = currentDay
            data.user = userName
            data.schedule = scheduleSave
            // console.log(data)
            if (scheduleSave.length !== 0) {
                if (!savedDates.includes(`${currentDay}`)){
                    await addNewSchedule(localStorage.getItem("access_token"), data)
                    setSaved(true)
                    alert(`Расписание сохранено`)
                }
                if (scheduleCurrentDay && savedDates.includes(`${currentDay}`)) {
                    await setUpdateSchedule(localStorage.getItem("access_token"),
                        scheduleCurrentDay[0].id, data)
                    setSaved(true)
                    alert(`Расписание обновленно`)
                }
                // else {
                //     if (savedDates.includes(`${currentDay}`)) {
                //         await setUpdateSchedule(scheduleCurrentDay[0].id, data)
                //         getBlocks(`${currentDay ? currentDay : "1"}`)
                //         alert(`Расписание обновленно`)
                //     }
                // }
            }
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }
    // Get Schedule Save current day
    const [scheduleCurrentDay, setScheduleCurrentDat] = useState([])
    const getScheduleCurrentDay = async () => {
        const result = await getDaySchedule(localStorage.getItem("access_token"),
            month,chanels[activeChanel]?.id, currentDay)
        if (result) {
            setScheduleCurrentDat(result)
        }
    }
    //Last saved day
    const [savedDates, setSavedDates] = useState([])
    const getLastSavedDay = async () => {
        const data = await getNumberLastDay(localStorage.getItem("access_token"),
            month,chanels[activeChanel]?.id)
        setSavedDates(data)
    }
    // console.log(savedDates.includes(`${currentDay}`))

    useEffect(() => {
        getBlocks(`${currentDay ? currentDay : "1"}`)
        getScheduleCurrentDay()
        getLastSavedDay()
    }, [currentDay, activeChanel, chanels, activeMonth, saved])

    const blockList = (rek,index) => {
        const dayName = getWeekDay(
            new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), currentDay)
        )
        const arr = []
        arrBlocks.map((mp) => {
            const data1 = mp[rek]
            if (data1) {
               arr.push(Number(data1?.length))
            }
        })
        const timeLimit = chanels[activeChanel]?.timeBlock?.[dayName]?.[index]
            ? Object.values(chanels[activeChanel]?.timeBlock?.[dayName]?.[index])[0]
            : 0
        let result1 = arr.length > 0 ? arr?.reduce((a, b) => a + b) : ""
        // let result = 180 - result1
        // console.log(timeLimit)
        let result = 0
        if (timeLimit === "0") {
            result = -1000
        } else {
            result = timeLimit - result1
        }
        // let result = timeLimit - result1
        return result
    }

    // On top
    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    //Сброс на онлайн при смене дня
    useEffect(() => {
        setSaved(false)
    }, [currentDay])

    // Get name Day of week
    const getWeekDay = (date) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[date.getDay()];
    }

    // console.log(currentDay)

    return (
        <div className="schedule container">
            <MonthSlider />
            <div className="pagination-list">{dayPerMonth.map((el) =>
                <p key={el} style={savedDates.includes(`${el}`) ? {color:"red"} : {}} className={el === currentDay ? "active-pagination" :
                    `${getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "СБ" ||
                    getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "ВС" ? "weekend" : ""}`
                }
                onClick={() => setCurrentDay(el)}
                >
                    {el}
                </p>)}
            </div>
            <div className="pagination-list" style={{marginTop:"-33px"}}>{dayPerMonth.map((el) =>
                <p key={el} style={savedDates.includes(`${el}`) ? {color:"red"} : {}} className={el === currentDay ? "active-pagination" :
                    `${getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "СБ" ||
                    getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el)) === "ВС" ? "weekend" : ""}`
                }
                   onClick={() => setCurrentDay(el)}
                >
                    {getWeekDay(new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), el))}
                </p>)}
            </div>
            <div
                style={
                savedDates.includes(`${currentDay}`) ?
                    {display: "flex", gap: 5, justifyContent: "center"}
                    : {display:"none"}
                }
            >
                <Button
                    style={saved ? {backgroundColor:"green"} : {}}
                    variant={"contained"}
                    onClick={()=>setSaved(!saved)}>{saved ? "Сохраненый" : "OnLine"}
                </Button>
            </div>
            <div className="schedule-list">
                {chanels[activeChanel]?.rekBlock.map((rek, index) => {
                    // getBlocks(`${currentDay ? currentDay : "1"} ${rek}`)
                    return (
                        <div className="schedule-table" key={index} id={index}>
                            <div className="schedule-table_title">{rek}</div>
                            <div>
                                <ScheduleDnd
                                    data={arrBlocks}
                                    rek={rek}
                                    lastTime={blockList(rek, index)}
                                    chanels={chanels}
                                    index={index}
                                    scheduleSave={scheduleSave}
                                    saved={saved}
                                    dataSaved={scheduleCurrentDay[0]?.schedule}
                                />
                            </div>
                            <div className={`schedule-table_title 
                            ${blockList(rek, index) < 0 ? "red" : ""}
                            ${blockList(rek, index) === -1000 ? "black" : ""}
                            `}>last time: {blockList(rek, index)}</div>
                        </div>
                    )
                })}
                <div style={saved ? {display:"none"} : {paddingTop: 15}}>
                    <Button variant="contained" onClick={handleSubmit(onSave)}>Сохранить</Button>
                    <Button variant="outlined" onClick={() => console.log('Отмена')}>Отмена</Button>
                </div>
            </div>
            {showTopBtn && (<div className="up-icon">
                <Fab onClick={() => onTop()} style={{background: "lightblue"}}>
                    <UpIcon />
                </Fab>
            </div>)}
        </div>
    )
}

export default Schedule