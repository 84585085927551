import './style.css'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import googleLogin from "../../API/googleLogin";
import {Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {googleLoginFetch, loginUser} from "../../API/api";

const LoginPage = () => {
    const {
        handleSubmit,
        register,
        reset,
        formState: {errors}
    } = useForm();
    const navigate = useNavigate()
    const [error, setError] = useState('')

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            console.log(data)
            const result = await loginUser(data)
            console.log(result)
            // localStorage.setItem('token', result.refresh)
            // localStorage.setItem('Access', result.access)
            // reset()
            // navigate("/profile")
        } catch (error) {
            console.dir(error);
            setError(JSON.parse(error.request.responseText));
            throw new Error("Что-то не так с пост запросом");
        }
    };

    // SHOW PASSWORD
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //TEST G-Login Custom
    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            console.log(codeResponse)
            const tokens = await googleLoginFetch(codeResponse.access_token);
            console.log(tokens)
            if (!tokens) {
                alert("Token null ")
            } else {
                // put login-page logic (i.e. navigating to dashboard page, fetching user from backend
               // using the new access token, etc
                localStorage.setItem("access_token", tokens.accessToken)
                localStorage.setItem("refresh_token", tokens.refreshToken)
                alert("login")
            }}
        ,
        // flow: 'auth-code',
    });

    return (
        <div className="login-page container">
            <h2>Вход</h2>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form">
                        <TextField
                            className="register-form-input"
                            sx={{ m: 1, width: '25ch' }}
                            type="email"
                            label="Login"
                            variant="outlined"
                            error={error.email ? true : false}
                            helperText={error.email ? `${error?.email}` : ""}
                            {...register("email", { required: true })} />
                        {/*<TextField*/}
                        {/*    className="register-form-input"*/}
                        {/*    type="password"*/}
                        {/*    label={langData.password}*/}
                        {/*    variant="outlined"*/}
                        {/*    error={error.password ? true : false}*/}
                        {/*    helperText={error.password ? `${error?.password}` : ""}*/}
                        {/*    {...register("password", { required: true })}*/}
                        {/*/>*/}
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                {...register("password", { required: true })}
                            />
                        </FormControl>
                    </div>
                    <div className="register-form-input" style={{color: "red"}}>{error.detail ? `${error?.detail}` : ""}</div>
                    <div className="login-enter">
                        <Button type={"submit"} variant="contained" className="login-enter-btn">
                            Login
                        </Button>

                        <Button onClick={() => login()}>
                        Sign in with Google 🚀{' '}
                    </Button>

                        {/*<GoogleLogin*/}
                        {/*    onSuccess={credentialResponse => {*/}
                        {/*        console.log(credentialResponse);*/}
                        {/*        const result = googleLoginFetch(credentialResponse.credential)*/}
                        {/*        console.log(result)*/}

                        {/*    }}*/}
                        {/*    onError={() => {*/}
                        {/*        console.log('Login Failed');*/}
                        {/*    }}*/}
                        {/*/>*/}




                        {/*<GoogleLogin*/}
                        {/*    clientId="979038049246-3lv3fn650nar1ppbjsutgvlk7ep3l5a6.apps.googleusercontent.com"*/}
                        {/*    buttonText="Google"*/}
                        {/*    onSuccess={async (response) => {*/}
                        {/*        const tokens = await googleLogin(response);*/}
                        {/*        console.log(response)*/}
                        {/*        if (!tokens) {*/}
                        {/*            alert("Error while logging in w/Google 1 ")*/}
                        {/*        } else {*/}
                        {/*            // put login-page logic (i.e. navigating to dashboard page, fetching user from backend*/}
                        {/*            // using the new access token, etc*/}
                        {/*            alert("login")*/}
                        {/*            console.log(tokens)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    onFailure={(response) => {*/}
                        {/*        alert("Error while logging in w/Google 2")*/}
                        {/*        console.log(response)*/}
                        {/*    }}*/}
                        {/*    cookiePolicy={"single_host_origin"}*/}
                        {/*/>*/}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage