import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Input} from "@mui/material";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";

const ModalAddEdit = ( {index, open, setOpen, setClips, clips, clip = null, setActiveClip}) => {
    //state Inpunts
    const [name, setName] = useState('');
    const [duration, setDuration] = useState('');
    const [short, setShort] = useState('');
    // console.log(clip)
    // console.log(name, duration, short)
    const isClip = () => {
        if (clip) {
            setName(clip.name)
            setDuration(clip.length)
            setShort(clip.short)
        } else {
            setName("")
            setDuration("")
            setShort("")
        }
    }

    useEffect(() => {
        isClip()
    }, [open])

    const {
        formState: {errors},
    } = useForm();
    // console.log(clip)

    const saveClips = () => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            const data = {}
            data.name = name
            data.length = duration
            data.short = short
            if (clip) {
                data.id = clip.id
                const newArr = clips.map((el) => {
                    if (el.id === clip.id) {
                        return data
                    } else {
                        return el
                    }
                })
                setClips(newArr);
            } else {
                data.id = index;
                setClips(
                    oldArray => [...oldArray, data]
                );
            }
                handleClose();
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    const handleClose = () => {
        setActiveClip(null);
        setOpen(false);
    };

     // console.log(index)
    return (
        <Dialog
            open={open}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`${clip === null ? "Добавление" : "Редактирование"} роликов рекламодателя:`}
            </DialogTitle>
            <DialogContent>
                <DialogContent id="alert-dialog-description">
                        <div className="mp-brand-form-clips">
                            <Input
                                className="mp-brand-form-clip_long"
                                placeholder="name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                            <Input
                                className="mp-brand-form-clip_short"
                                placeholder="length"
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />
                            <Input
                                className="mp-brand-form-clip_short"
                                placeholder="short"
                                value={short}
                                onChange={(e) => {
                                    setShort(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mp-brand-form-clips_btn">
                            <Button variant="contained" onClick={() => saveClips()}>Сохранить</Button>
                        </div>
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalAddEdit;