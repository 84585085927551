import "./style.css";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import MonthSlider from "../../modules/month-slider";
import {useSelector} from "react-redux";
import {getAllChanels, getGuestMonthMP, getMonthMP} from "../../API/api";
import MainPageReklist from "../../components/main-page-reklist";

const MainPage = () => {
    const {activeMonth} = useSelector((store) => store.month);
    const {activeChanel} = useSelector((store) => store.chanel);
    const { isGuest } = useSelector((store) => store.isGuest)
    const [dayPerMonth, setDayPerMonth] = useState([])
    const [currentDay, setCurrentDay] = useState(new Date().getDate())

    //useForm
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        reset,
    } = useForm();

    const monthName = (date) => (new Date(Date.parse(date)).toLocaleString('ru', {
        month: 'long', year: 'numeric'
    }))
    const month = monthName(activeMonth)

    const getWeekDay = (day) => {
        let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return days[new Date(new Date(activeMonth).getFullYear(), new Date(activeMonth).getMonth(), day).getDay()];
    }

    const currentDays = () => {
        setDayPerMonth([])
        const lastDayMonth = new Date(
            new Date(activeMonth).getFullYear(),
            new Date(activeMonth).getMonth()+1, 0).getDate()
        for (let i = 1; i <= lastDayMonth; i++) {
            setDayPerMonth(oldArray => [...oldArray, i])
        }
    }
    useEffect(() => {
        currentDays()
    }, [activeMonth])

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels();
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    const [mpList, setMpList] = useState([])
    const getMpList = async () => {
        setMpList([])
        const data = await getMonthMP(localStorage.getItem("access_token"),`${month}`, `${chanels[activeChanel]?.id}`);

        //Refactor reklama
        await data?.filter((el) => {
            const clips = el?.clips
            // console.log(clips);
            const dat3 = el?.reklama
            // console.log(dat3);
            dat3?.map((day) => {
                // console.log(day)
                if (day != null) {
                    Object.keys(day).forEach((key, index) => {
                        // const rek = day[key]
                        // console.log(rek)
                        clips.map((elem) => {
                            if (day[key] === elem.short) {
                                return day[key] = elem
                            }
                        })
                        return day[key]
                    })
                }
                return day
            })
})
        // ENd Refactor reklama

        setMpList(data)
    }

    const [guestRek, setGuestRek] = useState([])
    const getGuestRek = async () => {
            const data = await getGuestMonthMP(`${month}`, `${chanels[activeChanel]?.id}`)
            await setGuestRek(data)
    }

    const guestBlockList = (day, time, index) => {
        const nameDay = getWeekDay(day)
        let arr = []
        // getGuestRek(day)
        // console.log(guestRek)
        guestRek?.map((el) => {
            const dRek = el?.[day]
            const data = dRek?.[time]
            // console.log(data)
            if (data?.length > 0) {
                arr.push(Number(data))
            }
        })
        let result1 = arr?.length > 0 ? arr?.reduce((a, b) => a + b) : 0
        return Object.values(chanels[activeChanel]?.timeBlock?.[nameDay]?.[index])[0] - result1
    }

    // console.log(guestRek)

    useEffect(() => {
        if (!isGuest) {
            getMpList();
        }
        if (isGuest) {
            getGuestRek()
        }
    },[activeMonth, activeChanel, chanels, isGuest]);

    const blockList = (day, time, index) => {
        const nameDay = getWeekDay(day)
        const arr = []
            mpList?.map((el) => {
                if (el.status === true) {
                    const dRek = el?.reklama?.[day]
                    const data = dRek?.[time]
                    // console.log(data)
                    if (data?.length > 0) {
                        arr.push(Number(data.length))
                    }
                }

            })

        let result1 = arr?.length > 0 ? arr?.reduce((a, b) => a + b) : 0
        // let result = 180 - result1
        let result = Object.values(chanels[activeChanel]?.timeBlock?.[nameDay]?.[index])[0] - result1
        return result
    }

    // const [arrBlocks, setArrBlocks] = useState([])
    // const getBlocks = async (block) => {
    //     const data = await getDayRek(`${month}`, `${chanels[activeChanel]?.id}`, `${block}`)
    //     setArrBlocks(data)
    //     // timeBlocksConvert.push(data)
    //     // setArrBlocks(oldArray => [...oldArray, data])
    //     // setArrBlocks(prevState => ({
    //     //     ...prevState,
    //     //     [block]: data
    //     // }))
    //     return data
    // }

    // console.log(mpList)

    // const bgc = (time) => {
    //     if (time > 61) {
    //         return ""
    //     }
    //     if (46 <= time && time <= 60) {
    //         return "deepskyblue"
    //     }
    //     if (31 <= time && time <= 45) {
    //         return "green"
    //     }
    //     if (21 <= time && time <= 30) {
    //         return "yellow"
    //     }
    //     if (11 <= time && time <= 20) {
    //         return "orange"
    //     }
    //     if (1 <= time && time <= 10) {
    //         return "red"
    //     }
    //     if ( time <= 0) {
    //         return "black"
    //     }
    // }

    // const [showRekList, setShowRekList] = useState(false)
    //
    // const handleRekDisplay = () => {
    //     setShowRekList(!showRekList)
    // }

    const bgc = (time) => {
        if (time > 61) {
            return ""
        }
        if (46 <= time && time <= 60) {
            return "deepskyblue"
        }
        if (31 <= time && time <= 45) {
            return "green"
        }
        if (21 <= time && time <= 30) {
            return "yellow"
        }
        if (11 <= time && time <= 20) {
            return "orange"
        }
        if (1 <= time && time <= 10) {
            return "red"
        }
        if ( time <= 0) {
            return "black"
        }
    }

    return (
        <div className="MainPage container">
            <MonthSlider />
            <div className="mainpage-table">
                <div className="mainpage-table_time-value">
                    <div className="mainpage-table_header"></div>
                    {chanels?.[activeChanel]?.rekBlock?.map((el, index) => (
                        <div className="mainpage-table_data" key={index}>{el}</div>
                    ))}
                </div>
                <div className="mainpage-table_time">
                    {dayPerMonth?.map((day, ind) => {
                        // const dayRek = getBlocks(day)
                        // console.log(dayRek)
                        return (
                            <div key={ind} className="mainpage-table_time">
                                <div className="mainpage-table_header">{day}</div>

                                {chanels?.[activeChanel]?.rekBlock?.map((block, index) => (
                                        <div key={index}>

                                            {/*<div className={showRekInfo ? "mainpage-table_rek active" : "mainpage-table_rek"}>123</div>*/}
                                            { isGuest ?
                                                <div className={`mainpage-table_data ${bgc(Number(guestBlockList(day, block, index)))}`}>
                                                    {guestBlockList(day, block, index)}
                                                </div>
                                                :
                                                <MainPageReklist
                                                    day={day}
                                                    block={block}
                                                    time={blockList(day, block, index)}
                                                    chanels={chanels}
                                                />
                                            }


                                        </div>

                                ))
                                }
                            </div>
                        )})}
                </div>
            </div>
        </div>
    )
}

export default MainPage