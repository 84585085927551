import {Box, Button, Modal, TextField} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {addRole, setUpdateRole} from "../../API/api";


const AddEditRole = ({data = null, openRoleNodal, setOpenRoleNodal}) => {
    const accessToken = localStorage.getItem("access_token")
    const [roleName, setRoleName] = useState('')
    const [roleDescription, setRoleDescription] = useState('')

    const {
        formState: {errors}
    } = useForm();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const createRole = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            let data2 = {}
            data2.value = roleName
            data2.description = roleDescription
            if (data) {
                const result = await setUpdateRole(accessToken, data.id, data2)
                if (result) {
                    setOpenRoleNodal(false)
                    setRoleName('')
                    setRoleDescription('')
                }
            }
            if (!data) {
                const result = await addRole(accessToken, data2)
                if (result) {
                    setOpenRoleNodal(false)
                    setRoleName('')
                    setRoleDescription('')
                }
            }
        } catch (error) {
            console.dir(error);
            throw new Error("Что-то не так с пост запросом");
        }
    }

    useEffect(() => {
        if (data) {
            setRoleName(data.value)
            setRoleDescription(data.description)
        }
        if (!data) {
            setRoleName('')
            setRoleDescription('')
        }
    },[data])

    return (
        <Modal
            open={openRoleNodal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="add-user-form">
                    <TextField
                        id="outlined-basic"
                        label="Имя"
                        variant="outlined"
                        className="add-user-form_input"
                        value={roleName}
                        onChange={(e) => {
                            setRoleName(e.target.value);
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        label="Описание"
                        variant="outlined"
                        className="add-user-form_input"
                        value={roleDescription}
                        onChange={(e) => {
                            setRoleDescription(e.target.value);
                        }}
                    />
                    <div className="mp-brand-form-clips_btn">
                        <Button variant="contained" onClick={() => createRole()}>
                            {data ? "Обновить группу" : "Добавить группу"}
                        </Button>
                    </div>
                    <div className="add-user-form_btnclose" onClick={() => setOpenRoleNodal(false)}><HighlightOffIcon /></div>
                </div>
            </Box>
        </Modal>
    )
}

export default AddEditRole