import {Input} from "@mui/material";
import {useEffect, useState} from "react";
import styled from "styled-components";

const Container = styled.div`
    position: relative;
`

const SortedItemList = styled.div`
    position: absolute;
    top: 0;
    left: 252px;
    height: 300px;
    border: 1px solid black;
    border-radius: 3%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 5px 15px;
  background-color: white;
    >span:hover {
      background-color: deepskyblue;
      color: white;
    }
`

const BannerInputSelect = ({files, itemValue, setItemValue}) => {

    // Sort List File
    const [sortItems, setSortItems] = useState([])
    // const [itemFilter, setItemFilter] = useState('')
    useEffect(() => {
        if (itemValue.length > 0) {
            setSortItems(files?.filter((el) => el.toLowerCase().includes(itemValue.toLowerCase())))
            setShowDropdown(true)
        } else {
            setSortItems(files)
            setShowDropdown(false)
        }
        if (files?.find((el) => el.toLowerCase() === itemValue?.toLowerCase()) === itemValue) {
            setShowDropdown(false)
        }
        setItemValue(itemValue)
    }, [files, itemValue])
    const [showDropdown, setShowDropdown] = useState(false);
    const handleInputClick = (item) => {
        setItemValue(item);
        setShowDropdown(false);
    };
    // console.log(itemFilter)
    // console.log(files?.find((el) => el.toLowerCase() === itemValue?.toLowerCase()) === itemValue)
    return (
        <Container>
            <Input
                className="mp-head_input"
                placeholder="Имя баннера"
                type="text"
                value={itemValue}
                onChange={(e) => {
                    setItemValue(e.target.value)
                }}
            />
            {showDropdown && (
                <SortedItemList>
                    {sortItems.map((item, index) => (
                        <span key={index} onClick={() => handleInputClick(item)} style={{cursor:"pointer"}}>
                            {item}
                        </span>
                    ))}
                </SortedItemList>
            )}
        </Container>
    )
}

export default BannerInputSelect