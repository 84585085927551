import "./style.css"
import {useEffect, useState} from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ru} from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch} from "react-redux";
import {selectMonth} from "../../store/select-month";

const MonthChoose = () => {
    const [selectedDate, handleDateChange] = useState(new Date())
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(selectMonth(`${selectedDate}`))
    },[selectedDate])

    // console.log(selectedDate)

    return (
        <div className="month-choose">
            <div className="month-select">
                <MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
                    <DatePicker
                        variant="inline"
                        openTo="month"
                        views={["year", "month"]}
                        label="Выбор месяца и года"
                        type="local"
                        //helperText="Start from year selection"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

export default MonthChoose